import * as React from 'react'
import { browserHistory } from 'react-router'
import { FormError } from '../components/FormError'
import { CurrentUserStore } from '../stores/CurrentUserStore'
import { Routes } from '../constants/Routes'
import { LocalStorage } from '../utilities/LocalStorage'
import { ICognitoError, parseCongitoError } from '../utilities/cognito'

interface State {
  isLoading: boolean
  email: string
  errorMessage?: string
}

export class ForgotPassword extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props)
    this.state = {
      email: '',
      isLoading: false,
      errorMessage: undefined,
    }
  }

  async submit() {
    const { email } = this.state
    const lowerCaseEmail = email.toLowerCase()
    try {
      this.setState({ isLoading: true })
      if (!email) throw new Error('Email is required')
      if (email.includes('+'))
        throw new Error(
          'Please change your email to a valid email address first (email cannot contain "+")',
        )
      await CurrentUserStore.forgotPasswordV2(lowerCaseEmail)
      LocalStorage.setUnauthenticatedEmail(lowerCaseEmail)
      browserHistory.push(Routes.RESET_PASSWORD_V2)
    } catch (error) {
      const { code } = error as ICognitoError
      if (code === 'UserNotFoundException') {
        LocalStorage.setUnauthenticatedEmail(lowerCaseEmail)
        browserHistory.push(Routes.RESET_PASSWORD_V2)
      }
      this.setState({
        errorMessage: parseCongitoError(error),
        isLoading: false,
      })
    }
  }

  render() {
    return (
      <div>
        <div className='container pt-5'>
          <div className='row pt-3'>
            <div className='col-md-6 offset-md-3 mb-2'>
              <div>
                <div>
                  <a href={Routes.LOGIN_V2}>
                    <button className='btn btn-outline-success'>
                      <span className='fa fa-angle-left mr-2' />
                      Back to login
                    </button>
                  </a>
                </div>
                <br />
                <h1 className='display-4'>Forgot Password</h1>
                <br />
                <div>
                  <form>
                    <div className='form-group'>
                      <label>Email</label>
                      <input
                        type='email'
                        className='form-control'
                        value={this.state.email}
                        onChange={(e) => this.setState({ email: e.target.value })}
                      />
                    </div>
                    <div className='form-group'>
                      <p className='text-muted'>
                        We will send you an email with further instructions on resetting your
                        password.
                      </p>
                    </div>
                    <br />
                    {this.state.isLoading ? (
                      <button className='btn btn-lg btn-success w-100' disabled>
                        Submitting...
                      </button>
                    ) : (
                      <button
                        className='btn btn-lg btn-success w-100'
                        onClick={(e) => {
                          e.preventDefault()
                          this.submit()
                        }}
                      >
                        Submit
                      </button>
                    )}
                    <br />
                    <FormError message={this.state.errorMessage} />
                  </form>
                </div>
                <br />
                <p className='text-muted'>
                  Having trouble logging in? Contact{' '}
                  <a
                    href='mailto:support@getgreenline.co?subject=Trouble%20logging%20in'
                    target='_blank'
                    rel='noreferrer'
                  >
                    support@getgreenline.co
                  </a>
                </p>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
