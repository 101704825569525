import { observable, action, computed } from 'mobx'
import {
  API,
  axios,
  ICompany,
  IUpdateCompany,
  IUserResponse,
  IUpdateUserRequest,
  ICreateUserRequest,
  IEntityUser,
  IDevice,
  SecurityRole,
  CreateSecurityRole,
  UpdateSecurityRole,
  IBaseProduct,
  InventoryLogType,
  IPayment,
  PaymentFilter,
  IBaseLoyaltyTier,
  IAddOrEditLoyaltyTier,
  CreateOrUpdateTransfer,
  ICreateCustomer,
  ITransferSearchFilter,
  IUpdateBulkInventory,
  ICashCountUpdate,
  ICompanyContract,
  GiftCardType,
  IGiftCardContract,
  IPOSListing,
  Pagination,
  ICompanyWithMetaDataContract,
  MetaKeys,
} from '@getgreenline/homi-shared'
import { ProductStore } from './ProductStore'
import { SupplierStore } from './SupplierStore'
import Endpoints from '../../constants/Endpoints'
import { LocalStorage } from '../../utilities/LocalStorage'
import { FeatureToggleApi } from '@getgreenline/feature-toggle'
import { IFeatureToggleQueryParams } from '@getgreenline/feature-toggle/build/models'
import { FeatureToggle } from '../../constants/FeatureToggles'
import { LocationModels, LocationApi } from '@getgreenline/locations'
import { MetaDataType } from '@getgreenline/shared'
import { isNil } from 'lodash'
import { getHashKeys, ICreateHashKeyRequestContract, ITenantPathParams } from '@getgreenline/ocs'

export interface CloudinaryDetailsResponse {
  apiKey: string
  timestamp: string
  url: string
  signature: string
}

export class CurrentCompanyStore {
  @observable productStore?: ProductStore
  @observable supplierStore?: SupplierStore

  @observable company?: ICompany
  @observable summary?: any
  @observable locations?: LocationModels.ILocationContract[]
  @observable globalLocations?: LocationModels.IGlobalLocationContract[]
  @observable globalHashKeys?: ICreateHashKeyRequestContract[]
  @observable companyUser?: IEntityUser
  @observable users?: IUserResponse[]
  @observable securityRoles?: SecurityRole[]
  @observable devices?: IDevice[]
  @observable inventoryProducts?: IBaseProduct[]
  @observable loyaltyTiers?: IBaseLoyaltyTier[]
  @observable sortedLoyaltyTiers?: IBaseLoyaltyTier[]
  @observable mappedGiftCards: Map<string, IGiftCardContract> = new Map()
  @observable isWeedmapsEnabled = false
  @observable canUseLoyaltyPermissions = false
  @observable canUseLoyaltyPerSpend = false
  @observable canUseSMSMarketing = false
  @observable canUseWorldpay = false
  @observable showQueueEnhancements = false
  @observable showBrands = false
  @observable showLps = false
  @observable canUseCannabinoidPerBatch?: boolean
  @observable canViewShiftV2 = true
  @observable canViewEcommerceSettings = false
  @observable canViewDigitalSignage: boolean | null = null
  @observable productDetailsPackageDate = false
  @observable productDetailsTerpenesAndCannabinoids = false
  @observable ircc = false
  @observable canUseBundledDiscount = false
  @observable showBundledDiscounts = false
  @observable canUseLoyaltyPoints = false
  @observable posListing?: IPOSListing[]
  @observable posListingFetchTime?: string | Date
  @observable loyaltyPointsMultiplier = 100
  @observable canUseMBQuarterlyComplianceReport = false
  @observable loyaltyAsPaymentType = false
  @observable canUseOCSCompliance = false

  @action
  getById<T extends boolean>(companyId: number, includeMetaData?: T): Promise<ICompanyContract<T>> {
    return API.getCompanyById(companyId, !!includeMetaData as T).then((company) => {
      this.productStore = new ProductStore(company)
      this.supplierStore! = new SupplierStore(company)

      // Sub-stores must be initialized before the observable company is set, otherwise components will try to call sub-store methods when the company does not exist yet
      this.company = company

      return company
    })
  }

  @action
  async reloadCompany<T extends boolean>(includeMetaData?: T): Promise<void> {
    if (this.company) {
      const company = await API.getCompanyById(this.company.id, includeMetaData as T)
      this.company = company
    }
  }

  // Light-weight location array with all available locations
  @action
  getGlobalLocations = async () => {
    const param = new Pagination({
      offset: 0,
      limit: 100,
    })

    while (true) {
      const { data } = await LocationApi.getGlobalLocations(this.company!.id, param)

      this.globalLocations = [...(this.globalLocations || []), ...data]

      // If what is returned is less than the limit, that means there is no more location to fetch
      if (data.length < param.limit) break

      param.offset += param.limit
    }
  }

  @action
  getGlobalHashKeys = async () => {
    const tenantPathParams: ITenantPathParams = {
      gateway: Endpoints.AUTHZ_GATEWAY_URL,
      tenantId: `${this?.company?.id}`,
    }
    const { items: hashKeys } = await getHashKeys(tenantPathParams)
    this.globalHashKeys = hashKeys
  }

  @action
  getLocations = async (): Promise<LocationModels.ILocationContract[]> => {
    if (!this.company) {
      return new Promise((resolve, reject) => {
        resolve([] as LocationModels.ILocationContract[])
      })
    }

    const data = await LocationApi.getLocations(this.company.id, false)
    this.locations = data
    return data
  }

  @action
  getLocationsWithMetaData = async (): Promise<
    LocationModels.ILocationContractWithMetaData<MetaDataType>[]
  > => {
    if (!this.company) {
      return new Promise((resolve, reject) => {
        resolve([] as LocationModels.ILocationContractWithMetaData<MetaDataType>[])
      })
    }

    const data = await LocationApi.getLocations(this.company.id, true)
    this.locations = data
    return data
  }

  @action
  addLocation = async (locationObject: any) => {
    const res = await LocationApi.addLocation(this.company!.id, locationObject)
    this.getLocations()
    return res
  }

  @action
  editLocation = async (locationObject: any): Promise<LocationModels.ILocationContract> => {
    await LocationApi.updateLocation(this.company!.id, locationObject)
    await this.getLocations()
    return locationObject
  }

  @action
  reorderLocations(order: Array<{ locationId: number; index: number }>) {
    return API.reorderLocations(this.company!.id, order).then(() => {
      this.getLocations()
    })
  }

  @action
  deleteLocation = async (locationId: number) => {
    const res = await LocationApi.deleteLocation(this.company!.id, locationId)
    this.getLocations()
    return res
  }

  @action
  update(updateObject: IUpdateCompany) {
    return API.updateCompany(this.company!.id, updateObject).then((company) => {
      this.company = company
      return company
    })
  }

  @action
  async refreshBirchmountConfigs(companyId: number, locationId: number) {
    await LocalStorage.setBirchmountApiKey(companyId)
    await LocalStorage.setBirchmountLocId(companyId, locationId)
  }

  @action
  async getGiftCardContracts() {
    const giftCardContracts = await API.getGiftCardsByCompany(this.company!.id)

    giftCardContracts.forEach((gc) => {
      if (gc.productId !== undefined) {
        this.mappedGiftCards.set(gc.productId!, gc)
      }
    })

    return giftCardContracts
  }

  @action
  updateGiftCard(
    giftCardId: GiftCardType,
    giftCardContract: IGiftCardContract,
  ): Promise<IGiftCardContract> {
    return API.updateGiftCard(this.company!.id, giftCardId, giftCardContract)
  }

  @action
  getEntityUsers() {
    return API.getCompanyUsers(this.company!.id).then((users) => {
      this.users = users
      return users
    })
  }

  @action
  getDevices() {
    return API.getDevices(this.company!.id).then((devices) => {
      this.devices = devices
      return devices
    })
  }

  getEntityUserByUserId(userId: string) {
    return API.getCompanyUserById(this.company!.id, userId).then((user) => {
      return user
    })
  }

  @action
  addEntityUser(createUser: ICreateUserRequest) {
    return API.addCompanyUser(this.company!.id, createUser).then((user) => {
      this.getEntityUsers()
      return user
    })
  }

  @action
  editEntityUser(userId: string, updateUser: IUpdateUserRequest) {
    return API.editCompanyUser(this.company!.id, userId, updateUser).then((user) => {
      this.getEntityUsers()
      return user
    })
  }

  @action
  deleteEntityUser(userId: string) {
    return API.deleteCompanyUser(this.company!.id, userId).then(() => {
      return this.getEntityUsers()
    })
  }

  @action
  getSecurityRoles() {
    return API.getSecurityRoles(this.company!.id).then((securityRoles) => {
      this.securityRoles = securityRoles
      return securityRoles
    })
  }

  @action
  addSecurityRole(createObject: CreateSecurityRole) {
    return API.addSecurityRole(this.company!.id, createObject).then((securityRole) => {
      this.getSecurityRoles()
      return securityRole
    })
  }

  @action
  updateSecurityRole(updateObject: UpdateSecurityRole) {
    return API.updateSecurityRole(this.company!.id, updateObject.id, updateObject).then(
      (securityRole) => {
        this.getSecurityRoles()
        return securityRole
      },
    )
  }

  @action
  editDeviceCashAmount(userId: string, deviceCashAmount: number, note: string) {
    return axios
      .put(Endpoints.EDIT_DEVICE(this.company!.id, userId), {
        id: userId,
        deviceCashAmount: deviceCashAmount,
        note: note,
      })
      .then((response) => {
        this.getDevices()
        const entityUser = response.data as IUserResponse
        return entityUser
      })
  }

  @action
  resetDevicePassword(userId: string, password: string) {
    return axios
      .put(Endpoints.EDIT_DEVICE(this.company!.id, userId), {
        id: userId,
        password: password,
      })
      .then((response) => {
        this.getDevices()
        const entityUser = response.data as IUserResponse
        return entityUser
      })
  }

  getCustomerWithHistoryById(customerId: string) {
    return API.getCustomerById(this.company!.id, customerId)
  }

  getCustomerHistoricalPayments(customerId: string) {
    return axios
      .get(Endpoints.GET_CUSTOMER_HISTORICAL_PAYMENTS(this.company!.id, customerId))
      .then((response) => {
        const payments = response.data.payments as IPayment[]
        return payments
      })
  }

  addCustomer(createObject: ICreateCustomer) {
    return API.createCustomer(this.company!.id, createObject)
  }

  editCustomer(customerId: string, updateObject: ICreateCustomer) {
    return API.updateCustomer(this.company!.id, customerId, updateObject)
  }

  getLocationPayments(locationId: number, filter: PaymentFilter) {
    return API.getPaymentsAtLocation(this.company!.id, locationId, filter).then((response) => {
      const paginatedPayment = response
      const paginatedPayments = {
        payments: paginatedPayment.payments,
        limit: paginatedPayment.limit,
        offset: paginatedPayment.offset,
        total: paginatedPayment.total,
      }
      return paginatedPayments
    })
  }

  inProgressPayment(locationId: number, paymentId: string) {
    return axios.post(Endpoints.IN_PROGRESS_PAYMENT(this.company!.id, locationId, paymentId))
  }

  completePayment(locationId: number, paymentId: string) {
    return axios.post(Endpoints.COMPLETE_PAYMENT(this.company!.id, locationId, paymentId))
  }

  cancelPayment(locationId: number, paymentId: string, userId: string, notes: string | undefined) {
    return API.cancelPayment(this.company!.id, locationId, paymentId, userId, notes)
  }

  getSignedImageUploadUrl(fileExtension: string, contentType: string) {
    return axios
      .get(Endpoints.GET_SIGNED_IMAGE_UPLOAD_URL(this.company!.id, fileExtension, contentType))
      .then((response) => {
        const responseObject = response.data as {
          fileName: string
          signedUrl: string
        }
        return responseObject
      })
  }

  getSignedTempFileUploadUrl = async (fileExtension: string, contentType: string) => {
    try {
      const endPoint = Endpoints.GET_SIGNED_TEMP_FILE_UPLOAD_URL(
        this.company!.id,
        fileExtension,
        contentType,
      )
      const response = await axios.get(endPoint)
      return response.data as {
        fileName: string
        signedUrl: string
      }
    } catch (error) {
      console.error('Error in getSignedTempFileUploadUrl', error)
      throw error
    }
  }

  async getSignedImageUploadPublicUrl(): Promise<CloudinaryDetailsResponse> {
    try {
      const response = await axios.get(
        Endpoints.GET_SIGNED_IMAGE_UPLOAD_PUBLIC_URL(this.company!.id),
      )
      return response.data
    } catch (error) {
      console.error('Error in getSignedImageUploadPublicUrl', error)
      throw error
    }
  }

  getSignedAdminImageUploadPublicUrl = async (): Promise<CloudinaryDetailsResponse> => {
    try {
      const response = await axios.get(Endpoints.GET_SIGNED_ADMIN_IMAGE_UPLOAD_PUBLIC_URL())
      return response.data
    } catch (error) {
      console.error('Error in getSignedAdminImageUploadPublicUrl', error)
      throw error
    }
  }

  getSignedImageUrl(fileName: string) {
    return axios
      .get(Endpoints.GET_SIGNED_IMAGE_URL(this.company!.id, fileName))
      .then((response) => {
        const responseObject = response.data as {
          signedUrl: string
        }
        return responseObject
      })
  }

  getGraphValues(
    startDate: Date,
    endDate: Date,
    groupBy: 'hour' | 'day' | 'month',
    locationIds?: number[],
  ) {
    return API.getGraphValues(this.company!.id, startDate, endDate, groupBy, locationIds).then(
      (response) => {
        return response
      },
    )
  }

  @action
  getInventoryProducts() {
    return API.getInventoryProducts(this.company!.id).then((products) => {
      this.inventoryProducts = products
      return products
    })
  }

  updateBulkInventoryTransfer(
    fromLocationId: number,
    toLocationId: number,
    transferObjects: Array<{ productId: string; quantity: number; note?: string }>,
  ) {
    return axios.put(
      Endpoints.UPDATE_BULK_INVENTORY_TRANSFER(this.company!.id, fromLocationId, toLocationId),
      {
        inventoryToTransfer: transferObjects,
      },
    )
  }

  @action
  updateBulkInventory(locationId: number, bulkInventory: IUpdateBulkInventory) {
    return API.updateBulkInventory(this.company!.id, locationId, bulkInventory)
  }

  // WooCommerce

  @action
  syncWooCommerceProduct(productId: string) {
    return API.syncWooCommerceProduct(this.company!.id, productId)
  }

  @action
  syncWooCommerceInventory(productId: string) {
    return axios.post(Endpoints.SYNC_WOOCOMMERCE_INVENTORY(this.company!.id, productId))
  }

  @action
  deleteWooCommerceProduct(productId: string) {
    return axios.delete(Endpoints.DELETE_WOOCOMMERCE_PRODUCT(this.company!.id, productId))
  }

  // Loyalty

  @action
  getLoyaltyTiers() {
    return API.getLoyaltyTiers(this.company!.id).then((loyaltyTiers) => {
      this.loyaltyTiers = loyaltyTiers
      this.setSortedLoyaltyTiers(loyaltyTiers)
      return loyaltyTiers
    })
  }

  @action
  setSortedLoyaltyTiers(loyaltyTiers: IBaseLoyaltyTier[]) {
    this.sortedLoyaltyTiers = [
      ...loyaltyTiers.filter((loyalty) => loyalty.isDefault),
      ...loyaltyTiers.filter((loyalty) => !loyalty.isDefault),
    ]
  }

  @action
  addLoyaltyTier(createObject: IAddOrEditLoyaltyTier, updateAllCustomers = false) {
    return API.addLoyaltyTier(this.company!.id, createObject, updateAllCustomers).then(
      (loyaltyTier) => {
        this.getLoyaltyTiers()
        return loyaltyTier
      },
    )
  }

  @action
  editLoyaltyTier(
    loyaltyTierId: number,
    createObject: IAddOrEditLoyaltyTier,
    updateAllCustomers = false,
  ) {
    return API.editLoyaltyTier(
      this.company!.id,
      loyaltyTierId,
      createObject,
      updateAllCustomers,
    ).then((loyaltyTier) => {
      this.getLoyaltyTiers()
      return loyaltyTier
    })
  }

  @action
  getWeedmapsIntegrationStatus = async () => {
    const companyExternalSources = await API.getCompanyExternalSources(this.company!.id)
    const weedmapsExternalSource = companyExternalSources.find(
      (externalSource) => externalSource.name === 'Weedmaps',
    )
    this.isWeedmapsEnabled = !!(weedmapsExternalSource && weedmapsExternalSource.isEnabled)
  }

  @computed
  get isNegativeInventorySaleBlocked(): boolean {
    return this.company?.blockNegativeInventorySale || false
  }

  // Transfers
  getTransfers(filters: ITransferSearchFilter) {
    return API.getTransfers(this.company!.id, filters)
  }

  getTransferById(transferId: string) {
    return API.getTransferById(this.company!.id, transferId)
  }

  createTransfer(createObject: CreateOrUpdateTransfer) {
    return API.createTransfer(this.company!.id, createObject)
  }

  updateTransfer(transferId: string, updateObject: CreateOrUpdateTransfer) {
    return API.updateTransfer(this.company!.id, transferId, updateObject)
  }

  // inventory log
  modifyInventoryLogType(inventoryLogId: number, newLogType: InventoryLogType) {
    return API.updateInventoryLogType(this.company!.id, inventoryLogId, newLogType)
  }

  updateCashCount(deviceId: string, shiftId: number, cashCountUpdateObject: ICashCountUpdate) {
    return API.updateCashCount(this.company!.id, deviceId, shiftId, cashCountUpdateObject)
  }

  isFeatureEnabled = async (
    featureId: string,
    params?: IFeatureToggleQueryParams,
  ): Promise<boolean> => {
    try {
      const { enabled } = await FeatureToggleApi.getFeatureToggle(
        this.company!.id,
        featureId,
        params,
      )

      return enabled
    } catch (error) {
      // Disable feature in case of error
      return false
    }
  }

  @action
  getPOSListing = async (locationId?: number) => {
    if (!this.company || !this.locations || this.locations.length === 0) return

    const location = locationId || this.locations[0].id
    const posListing = await API.getPOSListings(this.company.id, location)

    this.posListing = posListing
    this.posListingFetchTime = new Date()
  }

  @action
  getLoyaltyPermissionsStatus = async () => {
    const canUseLoyaltyPermissions = await this.isFeatureEnabled(FeatureToggle.LOYALTY_PERMISSIONS)
    this.canUseLoyaltyPermissions = canUseLoyaltyPermissions
  }

  @action
  getLoyaltyPerSpendStatus = async () => {
    const canUseLoyaltyPerSpend = await this.isFeatureEnabled(FeatureToggle.LOYALTY_PER_SPEND)
    this.canUseLoyaltyPerSpend = canUseLoyaltyPerSpend
  }

  @action
  getSMSMarketingStatus = async () => {
    const canUseSMSMarketing = await this.isFeatureEnabled(FeatureToggle.SMS_MARKETING)
    this.canUseSMSMarketing = canUseSMSMarketing
  }

  @action
  getQueueEnhancementsFeature = async () => {
    const showQueueEnhancements = await this.isFeatureEnabled(
      FeatureToggle.PARKED_SALE_ENHANCEMENTS,
    )
    this.showQueueEnhancements = showQueueEnhancements
  }

  @action
  getBrandsFeature = async () => {
    const brandsEnabled = await this.isFeatureEnabled(FeatureToggle.VIEW_BRANDS)
    this.showBrands = brandsEnabled
  }

  @action
  getLpsFeature = async () => {
    const lpsEnabled = await this.isFeatureEnabled(FeatureToggle.VIEW_LPS)
    this.showLps = lpsEnabled
  }

  @action
  getWorldpayStatus = async () => {
    const canUseWorldpay = await this.isFeatureEnabled(FeatureToggle.CAN_USE_WORLDPAY)
    this.canUseWorldpay = canUseWorldpay
  }

  @action
  getCannabinoidPerBatchStatus = async () => {
    const canUseCannabinoidPerBatch = await this.isFeatureEnabled(
      FeatureToggle.CANNABINOID_PER_BATCH,
    )

    this.canUseCannabinoidPerBatch = canUseCannabinoidPerBatch
  }

  @action
  getViewShiftV2Status = async () => {
    const canViewShiftV2 = await this.isFeatureEnabled(FeatureToggle.CAN_VIEW_SHIFTV2)
    if (canViewShiftV2 === false) {
      this.canViewShiftV2 = canViewShiftV2
    }
  }

  @action
  getViewEcommerceSettings = async () => {
    const canViewEcommerceSettings = await this.isFeatureEnabled(
      FeatureToggle.CAN_VIEW_ECOMMERCE_SETTINGS,
    )
    this.canViewEcommerceSettings = canViewEcommerceSettings
  }

  @action
  getCanViewDigitalSignage = async () => {
    const canViewDigitalSignage = await this.isFeatureEnabled(
      FeatureToggle.CAN_VIEW_DIGITAL_SIGNAGE,
    )
    this.canViewDigitalSignage = canViewDigitalSignage
  }

  @action
  getProductDetailsPackageDateStatus = async () => {
    const productDetailsPackageDate = await this.isFeatureEnabled(
      FeatureToggle.PRODUCT_DETAILS_PACKAGE_DATE,
    )
    this.productDetailsPackageDate = productDetailsPackageDate
  }

  @action
  getProductDetailsTerpenesAndCannbinoidsStatus = async () => {
    const productDetailsTerpenesAndCannabinoids = await this.isFeatureEnabled(
      FeatureToggle.PRODUCT_DETAILS_TERPENES_AND_CANNABINOIDS,
    )
    this.productDetailsTerpenesAndCannabinoids = productDetailsTerpenesAndCannabinoids
  }

  @action
  getIRCCStatus = async () => {
    const ircc = await this.isFeatureEnabled(FeatureToggle.IRCC)
    this.ircc = ircc
  }

  @action
  getUseBundledDiscountStatus = async () => {
    const canUseBundledDiscount = await this.isFeatureEnabled(FeatureToggle.USE_BUNDLED_DISCOUNT)
    this.canUseBundledDiscount = canUseBundledDiscount
  }

  @action
  setShowBundledDiscounts = (showBundledDiscounts: boolean) => {
    if (this.canUseBundledDiscount) {
      this.showBundledDiscounts = showBundledDiscounts
    }
  }

  @action
  getCanUseLoyaltyPoints = async () => {
    const canUseLoyaltyPoints = await this.isFeatureEnabled(FeatureToggle.CAN_USE_LOYALTY_POINTS)
    this.canUseLoyaltyPoints = canUseLoyaltyPoints
  }

  @action
  getCanUseMBQuarterlyComplianceReport = async () => {
    const canUseMBQuarterlyComplianceReport = await this.isFeatureEnabled(
      FeatureToggle.MB_QUARTERLY_COMPLIANCE_REPORT,
    )
    this.canUseMBQuarterlyComplianceReport = canUseMBQuarterlyComplianceReport
  }

  @action
  getCanUseOCSComplianceStatus = async () => {
    const canUseOCSCompliance = await this.isFeatureEnabled(FeatureToggle.CAN_USE_OCS_COMPLIANCE)
    this.canUseOCSCompliance = canUseOCSCompliance
  }

  @action
  async refreshLoyaltyPointsMultiplier() {
    const companyWithMetaData: ICompanyWithMetaDataContract<MetaDataType.INTEGER> =
      await API.getCompanyById(this.company!.id, true)

    const loyaltyPointsMultiplier = companyWithMetaData.metaData[MetaKeys.loyaltyPointsMultiplier]
    if (isNil(loyaltyPointsMultiplier)) {
      this.loyaltyPointsMultiplier = 100
      return
    }

    this.loyaltyPointsMultiplier = loyaltyPointsMultiplier
  }

  @action
  getLoyaltyAsPaymentType = async () => {
    this.loyaltyAsPaymentType = await this.isFeatureEnabled(FeatureToggle.LOYALTY_AS_PAYMENT_TYPE)
  }
}
