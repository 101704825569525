import { CurrentCompanyStore } from '../../../../stores/CurrentCompanyStore'
import {
  API,
  IPaymentQueueWithExternalSource,
  ICompany,
  PaymentQueueSearchFilter,
  PaymentQueueStatus,
  ILocation,
  IDevice,
  IPOSListing,
  IPaymentQueueLine,
} from '@getgreenline/homi-shared'
import { observable, action, computed } from 'mobx'
import { message } from 'antd'
import { parseErrorMsg } from '../../../../utilities/helpers'
import moment from 'moment'
import { flatten } from 'lodash'
import { discountApi, DiscountModels } from '@getgreenline/products'
import {
  isPaymentQueueLineNegativeInventorySale,
  negativeInventorySaleMessage,
} from '@getgreenline/payments'
import notificationSound from '../../../../audio/parked_sale_notification.wav'

export enum ParkedSaleTypes {
  PAID_ORDER_PLACED = 'paidOrderPlaced',
  PAID_IN_PROGRESS = 'paidInProgress',
  NOT_PAID_ORDER_PLACED = 'notPaidOrderPlaced',
  NOT_PAID_IN_PROGRESS = 'notPaidInProgress',
}

interface IAlertPaymentQueues {
  paidOrderPlaced: IPaymentQueueWithExternalSource[]
  paidInProgress: IPaymentQueueWithExternalSource[]
  notPaidOrderPlaced: IPaymentQueueWithExternalSource[]
  notPaidInProgress: IPaymentQueueWithExternalSource[]
}

export class ParkedSaleStore {
  defaultFilter = {
    locationIds: [],
    status: [
      PaymentQueueStatus.PENDING,
      PaymentQueueStatus.READY_FOR_PICKUP,
      PaymentQueueStatus.OUT_FOR_DELIVERY,
    ],
    devices: [],
    limit: 20,
    offset: 0,
    startDate: moment().subtract(1, 'month').toDate(),
    endDate: moment().toDate(),
  }

  constructor(public store: CurrentCompanyStore) {
    this.initiateStore()
  }

  initiateStore = async () => {
    await this.setLocations()
    await this.setDevices()
    await this.searchPaymentQueues()
    await this.setMappedPOSListing()
    this.initialLoad = false
  }

  @observable company: ICompany | undefined = this.store.company
  @observable isDashboardBarcodeValidationEnabled: boolean =
    this.company?.isDashboardBarcodeValidationEnabled || false
  @observable blockNegativeInventorySale: boolean = this.company?.blockNegativeInventorySale || true
  @observable locations: ILocation[] = []
  @observable devices: IDevice[] = []
  @observable loading = false
  @observable total = 0
  @observable offset: number = this.defaultFilter.offset
  @observable limit: number = this.defaultFilter.limit
  @observable startDate: Date = this.defaultFilter.startDate
  @observable endDate: Date = this.defaultFilter.endDate
  @observable private mappedPaymentQueues: Map<number, IPaymentQueueWithExternalSource> = new Map<
    number,
    IPaymentQueueWithExternalSource
  >()
  get paymentQueues() {
    return Array.from(this.mappedPaymentQueues.values())
  }
  @observable filter: PaymentQueueSearchFilter = new PaymentQueueSearchFilter(this.defaultFilter)
  @observable filteredLocations: number[] = []
  @observable filteredStatus: string[] = []
  @observable isRefreshed = false
  @observable isParkedSaleModalOpen = false
  @observable parkedSaleModalQueue: IPaymentQueueWithExternalSource | undefined
  @observable productLoading = false
  @observable private mappedPOSListing = new Map<string, IPOSListing>()
  @observable productPairQueue: Map<
    string,
    { product: IPOSListing; paymentLine: IPaymentQueueLine }
  > = new Map()
  @observable hiddenQueueLoading = false
  @observable posListingLoading = false
  @observable private initialLoad = true
  @observable private lastPaymentQueueSearch: Date = new Date()
  @observable private lastDiscountFetch: Date | null = null
  @observable private mappedDiscounts = new Map<number, DiscountModels.IDiscount>()

  compareOneHourFromNow(date: Date) {
    const oneHour = 60 * 60 * 1000
    const compareTimeFromNow = moment().diff(moment(date))

    return compareTimeFromNow < oneHour
  }

  @computed
  get flatPOSListing(): IPOSListing[] {
    return Array.from(this.mappedPOSListing.values())
  }

  @action
  setLocations = async () => {
    const locations = await this.store.getLocations()
    this.locations = locations
    locations.forEach((location) => this.filter.toggleLocationId(location.id))
  }

  @action
  setDevices = async () => {
    const devices = await this.store.getDevices()
    this.devices = devices
  }

  @action
  searchPaymentQueues = async (shouldPlayNotification?: boolean) => {
    if (this.loading) {
      return
    }
    if (this.isDateLimitExceeded) {
      return
    }

    const errorText = 'An error occurred while loading payment queues'
    try {
      if (!this.company) {
        throw errorText
      }
      this.loading = true
      this.isRefreshed = true
      this.filteredLocations = this.filter.locationIds
      this.filteredStatus = this.filter.status

      const paginatedPaymentQueues = await API.getFilteredAndPaginatedPaymentQueues(
        this.company.id,
        this.filter,
      )

      const newQueueExists = this.hasNewPaymentQueueInList(paginatedPaymentQueues.paymentQueues)
      this.lastPaymentQueueSearch = new Date()

      const newMappedPaymentQueues = new Map<number, IPaymentQueueWithExternalSource>()

      let flattendPaymentLines: IPaymentQueueLine[] = []

      paginatedPaymentQueues.paymentQueues.forEach((pq) => {
        newMappedPaymentQueues.set(pq.id, pq)

        flattendPaymentLines = [...flattendPaymentLines, ...pq.paymentLines]
      })

      const manualDiscounts = await discountApi.getAllDiscountsV2(this.company.id, {
        isAutomatic: false,
        updateDate: this.lastDiscountFetch,
      })
      this.lastDiscountFetch = new Date()

      manualDiscounts.forEach((discount) => {
        if (discount.isDeleted) {
          this.mappedDiscounts.delete(discount.id)
        } else {
          this.mappedDiscounts.set(discount.id, discount)
        }
      })

      for (const paymentLine of flattendPaymentLines) {
        if (paymentLine.productId !== null) continue

        if (paymentLine.discountId) {
          const discount = this.mappedDiscounts.get(paymentLine.discountId)
          paymentLine.productName = discount!.name
        } else {
          paymentLine.productName = this.createNameForDiscountWithoutId(paymentLine)
        }
      }

      this.total = paginatedPaymentQueues.total
      this.offset = paginatedPaymentQueues.offset
      this.limit = paginatedPaymentQueues.limit
      this.mappedPaymentQueues = newMappedPaymentQueues

      if (shouldPlayNotification && newQueueExists) {
        this.playNotificationSound()
      }
    } catch (error) {
      message.error(parseErrorMsg(error) || errorText)
    } finally {
      this.loading = false
      if (this.isParkedSaleModalOpen && this.parkedSaleModalQueue) {
        this.selectedModalPaymentQueue(this.parkedSaleModalQueue)
      }
    }
  }

  createNameForDiscountWithoutId = (paymentLine: IPaymentQueueLine) => {
    return paymentLine.discountPercentage === 0
      ? `$${(paymentLine.discountPrice / 100).toFixed(2)} off cart`
      : `${paymentLine.discountPercentage}% off cart`
  }

  @action
  setRefreshed = (isRefreshed: boolean) => {
    this.isRefreshed = isRefreshed
  }

  @action
  selectedModalPaymentQueue = async (modalQueue: IPaymentQueueWithExternalSource) => {
    const errorText = 'An error occurred while loading payment queues'

    if (!this.company) {
      return
    }

    const matchingIndex = this.paymentQueues.findIndex((queue) => queue.id === modalQueue.id)

    if (matchingIndex < 0) {
      try {
        this.hiddenQueueLoading = true
        const hiddenPaymentQueue = await API.getPaymentQueueById(
          this.company.id,
          modalQueue.locationId!,
          modalQueue.id,
        )
        this.parkedSaleModalQueue = hiddenPaymentQueue
      } catch (error) {
        message.error(parseErrorMsg(error) || errorText)
      } finally {
        this.hiddenQueueLoading = false
      }
    }
  }

  @action
  setToggleParkedSaleModal = (isOpen: boolean, paymentQueueId?: number) => {
    this.isParkedSaleModalOpen = isOpen
    if (isOpen && paymentQueueId) {
      const parkedSaleModalQueue = this.paymentQueues.filter(
        (queue) => queue.id === paymentQueueId,
      )[0]
      this.parkedSaleModalQueue = parkedSaleModalQueue
    } else {
      this.parkedSaleModalQueue = undefined
    }
  }

  @action
  setMappedPOSListing = async () => {
    const errorText = 'An error occurred while loading product lists'

    const { posListing, posListingFetchTime, getPOSListing } = this.store

    try {
      if (!posListing || moment().isSameOrAfter(moment(posListingFetchTime).add(15, 'm'))) {
        // On store initialize, fetch POS Listing if there is no data, or it's been more than 15 minutes since last fetch
        this.posListingLoading = true
        await getPOSListing()
      }

      this.store.posListing?.forEach((listing) => {
        this.mappedPOSListing.set(listing.id, listing)

        listing.childProducts.forEach((childProduct) => {
          this.mappedPOSListing.set(childProduct.id, childProduct)
        })
      })
    } catch (error) {
      message.error(parseErrorMsg(error) || errorText)
    } finally {
      this.posListingLoading = false
    }
  }

  @action
  setProductPairQueue = async () => {
    const errorText = 'An error occurred while loading product inventory'

    if (!this.parkedSaleModalQueue || !this.parkedSaleModalQueue.locationId || !this.company) {
      return
    }

    try {
      this.productLoading = true
      const { locationId, paymentLines } = this.parkedSaleModalQueue

      const paymentLinesInventory = await this.getPaymentLinesInventory(
        this.company.id,
        locationId,
        paymentLines,
      )

      paymentLines.forEach((paymentLine) => {
        const { productId } = paymentLine

        if (!productId) {
          return
        }

        const product = this.mappedPOSListing.get(productId)
        const inventory = paymentLinesInventory.find(
          (inventory) => inventory.productId === productId,
        )

        if (product && inventory) {
          this.productPairQueue.set(productId, {
            product: { ...product, quantity: inventory.quantity, batches: inventory.batches },
            paymentLine,
          })
        }
      })
    } catch (error) {
      message.error(parseErrorMsg(error) || errorText)
    } finally {
      this.productLoading = false
    }
  }

  @action
  setParkedSaleStatus = async (
    modalQueue: IPaymentQueueWithExternalSource,
    newQueueStatus: PaymentQueueStatus,
  ) => {
    const errorText = 'An error occurred while updating the payment queue'

    try {
      if (!this.company || !modalQueue || !modalQueue.locationId) {
        throw errorText
      }

      const matchingIndex = this.paymentQueues.findIndex((queue) => queue.id === modalQueue.id)
      if (matchingIndex > -1) {
        const newParkedSaleQueue = await API.updatePaymentQueueStatus(
          this.company.id,
          modalQueue.locationId,
          modalQueue.id,
          newQueueStatus,
        )
        this.paymentQueues.splice(matchingIndex, 1, newParkedSaleQueue)
        this.parkedSaleModalQueue = newParkedSaleQueue
        await this.searchPaymentQueues()
      } else {
        throw errorText
      }
    } catch (error) {
      message.error(parseErrorMsg(error) || errorText)
    }
  }

  @action
  setCancelParkedSale = async () => {
    const errorText = 'An error occurred while updating the payment queue'
    try {
      const modalQueue = this.parkedSaleModalQueue
      if (!this.company || !modalQueue || !modalQueue.locationId) {
        throw errorText
      }
      await API.updatePaymentQueueStatus(
        this.company.id,
        modalQueue.locationId,
        modalQueue.id,
        PaymentQueueStatus.CANCELLED,
      )
      await this.searchPaymentQueues()
    } catch (error) {
      message.error(parseErrorMsg(error) || errorText)
    }
  }

  isPaymentQueueLineNegativeInventorySale = (paymentQueueLine: IPaymentQueueLine): boolean => {
    if (!paymentQueueLine.productId) {
      return false
    }

    const productPair = this.productPairQueue.get(paymentQueueLine.productId)

    if (!productPair) {
      return false
    }

    return isPaymentQueueLineNegativeInventorySale(paymentQueueLine, productPair.product)
  }

  paymentLineNegativeInventorySaleMessage = (
    paymentQueueLine: IPaymentQueueLine,
  ): string | null => {
    if (!paymentQueueLine.productId) {
      return null
    }

    const productPair = this.productPairQueue.get(paymentQueueLine.productId)

    if (!productPair) {
      return null
    }

    return negativeInventorySaleMessage(productPair.product)
  }

  @computed
  get alertPaymentQueues(): IAlertPaymentQueues {
    return this.paymentQueues.reduce(
      (acc: IAlertPaymentQueues, queue: IPaymentQueueWithExternalSource) => {
        const isPaid = queue.hasOwnProperty('completedPayment')
        const queueStatus = queue.status

        switch (true) {
          case isPaid && queueStatus === PaymentQueueStatus.PENDING:
            acc[ParkedSaleTypes.PAID_ORDER_PLACED].push(queue)
            break
          case (isPaid && queueStatus === PaymentQueueStatus.READY_FOR_PICKUP) ||
            queueStatus === PaymentQueueStatus.OUT_FOR_DELIVERY:
            acc[ParkedSaleTypes.PAID_IN_PROGRESS].push(queue)
            break
          case !isPaid && queueStatus === PaymentQueueStatus.PENDING:
            acc[ParkedSaleTypes.NOT_PAID_ORDER_PLACED].push(queue)
            break
          case (!isPaid && queueStatus === PaymentQueueStatus.READY_FOR_PICKUP) ||
            queueStatus === PaymentQueueStatus.OUT_FOR_DELIVERY:
            acc[ParkedSaleTypes.NOT_PAID_IN_PROGRESS].push(queue)
            break
        }

        return acc
      },
      {
        paidOrderPlaced: [],
        paidInProgress: [],
        notPaidOrderPlaced: [],
        notPaidInProgress: [],
      },
    )
  }

  @action
  setStartDate(date: Date) {
    this.startDate = date
    this.filter.setStartDate(date)
  }

  @action
  setEndDate(date: Date) {
    this.endDate = date
    this.filter.setEndDate(date)
  }

  @computed
  get currentLocation(): ILocation | undefined {
    if (!this.parkedSaleModalQueue) {
      return
    }
    const { locationId } = this.parkedSaleModalQueue

    if (!locationId) {
      return
    }

    const currentLocation = this.locations.find((location) => location.id === locationId)
    return currentLocation
  }

  @computed
  get currentDevice(): IDevice | undefined {
    if (!this.parkedSaleModalQueue) {
      return
    }
    const { deviceId } = this.parkedSaleModalQueue

    if (!deviceId) {
      return
    }

    const currentDevice = this.devices.find((device) => device.id === deviceId)
    return currentDevice
  }

  @computed
  get isDateLimitExceeded(): boolean {
    const durationInMonths = moment(this.endDate).diff(moment(this.startDate), 'months')

    // Maximum number of months
    return durationInMonths >= 3
  }

  getPaymentLinesInventory = async (
    companyId: number,
    locationId: number,
    paymentLines: IPaymentQueueLine[],
  ) => {
    let productIds: string[] = []

    paymentLines.forEach((paymentLine) => {
      const { productId } = paymentLine
      if (productId) {
        productIds = [...productIds, productId]
      }
    })

    if (productIds.length === 0) return []

    const productsWithInventory = await API.getBasicInventory(companyId, locationId, {
      productIds,
    })

    return productsWithInventory
  }

  hasNewPaymentQueueInList = (newPaymentQueues: IPaymentQueueWithExternalSource[]): boolean => {
    return (
      newPaymentQueues.filter((newPaymentQueue) => {
        const exists = !!this.mappedPaymentQueues.get(newPaymentQueue.id)
        return (
          !exists &&
          new Date(newPaymentQueue.createDate) >= this.lastPaymentQueueSearch &&
          newPaymentQueue.status === PaymentQueueStatus.PENDING
        )
      }).length > 0
    )
  }

  playNotificationSound = () => {
    if (!this.initialLoad) {
      const sound = new Audio(notificationSound)
      sound.autoplay = false
      sound.play()
    }
  }
}
