import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { POSStore } from '../../../stores/POSStore'
import { EmployeeSelectModal } from './EmployeeSelectModal'
import { UserOutlined } from '@ant-design/icons'
import { THEME_COLORS } from '@getgreenline/shared'
import { BlazeButton } from '../../../components/CustomButtons/BlazeButton'

interface Props {
  posStore?: POSStore
}

interface State {
  showEmployeeSelectModal: boolean
}

@inject('posStore')
@observer
export class EmployeeNavbar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      showEmployeeSelectModal: false,
    }
  }

  togglePopover() {
    this.setState({
      showEmployeeSelectModal: !this.state.showEmployeeSelectModal,
    })
  }

  render() {
    const shift = this.props.posStore!.currentShift

    return (
      <span>
        {shift && shift.currentUserId && shift.currentUserName ? (
          <button
            id='employee-popover-target'
            className='btn btn-invisible btn-sm text-white'
            onClick={() => this.togglePopover()}
          >
            <u>{shift.currentUserName}</u>
          </button>
        ) : (
          <span id='employee-popover-target' onClick={() => this.togglePopover()}>
            <BlazeButton
              blazeButtonType='distro'
              buttonProps={{ style: { marginRight: 8 } }}
              titleProps={{
                title: (
                  <div style={{ color: THEME_COLORS.BRAND.black }}>
                    <span className='mr-2'>
                      <UserOutlined translate='Select employee' />
                    </span>
                    Select employee
                  </div>
                ),
              }}
            />
          </span>
        )}
        {this.state.showEmployeeSelectModal && (
          <EmployeeSelectModal onHide={() => this.setState({ showEmployeeSelectModal: false })} />
        )}
      </span>
    )
  }
}
