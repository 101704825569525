import * as React from 'react'
import { observer } from 'mobx-react'
import { FormError } from '../../components/FormError'
import { CurrentUserStore } from '../../stores/CurrentUserStore'
import { RegisterModel } from './RegisterModel'
import { Email } from './Email'
import { Company } from './Company'
import Logo from '../../images/logo-256.png'
import './index.scss'
import { LoadingAnimation } from '../../components/LoadingAnimation'
import { NoPermissionsResult } from '../../components/NoPermissionsResult'
import { axios } from '@getgreenline/shared'
import { BLAZE_COMPANY } from '../../constants/general'

interface State {
  isSaving: boolean
  registerError?: string
}

@observer
export class RegisterV2 extends React.Component<{}, State> {
  currentUserStore = new CurrentUserStore()

  registerModel = new RegisterModel()

  constructor(props: {}) {
    super(props)
    this.state = {
      isSaving: false,
      registerError: undefined,
    }
  }

  componentDidMount = async () => {
    const authToken = window.localStorage.getItem('authToken')

    if (authToken !== null) {
      try {
        await this.currentUserStore.getCurrentHumanUser()
      } catch (error) {
        window.location.href = '/'
      }
    }
  }

  register = async () => {
    this.setState({
      isSaving: true,
      registerError: undefined,
    })
    try {
      const { name, email, companyName, country, timezone, province } = this.registerModel
      await axios.post('/api/v2/register', {
        name,
        email,
        companyName,
        country,
        timezone,
        province,
      })
      window.location.href = '/admin'
    } catch (error) {
      this.setState({
        isSaving: false,
        registerError: error.response.data.message || 'There as an error registering',
      })
    }
  }

  render() {
    const user = this.currentUserStore.currentUser
    if (process.env.NODE_ENV !== 'development') {
      if (!user) {
        return (
          <div className='full-height'>
            <div className='container'>
              <LoadingAnimation />
            </div>
          </div>
        )
      } else if (!user.isAdmin) {
        return <NoPermissionsResult />
      }
    }

    return (
      <div id='register'>
        <div className='container'>
          <div>
            <img className='mr-3' src={Logo} style={{ width: '30px', height: '30px' }} />
            <span>{BLAZE_COMPANY} POS</span>
          </div>

          <div style={{ height: '5em' }} />

          <div className='row'>
            <div className='col-md-6'>
              <Email registerModel={this.registerModel} />
              <br />
              <Company registerModel={this.registerModel} />
              <br />
              <button
                className='btn btn-success btn-lg w-100'
                onClick={() => this.register()}
                disabled={
                  this.state.isSaving ||
                  !this.registerModel.name ||
                  !this.registerModel.companyName ||
                  !this.registerModel.email
                }
              >
                {this.state.isSaving ? 'Registering...' : 'Register'}
                <br />
                <small>Will send email to user</small>
              </button>

              <br />

              <p>
                <FormError message={this.state.registerError} />
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
