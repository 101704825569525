export const Routes = {
  INDEX: `/`,
  ABOUT: `/about`,
  LOGIN: '/loginV2',
  LOGIN_V2: `/loginV2`,
  REGISTER: `/registerV2`,
  REGISTER_V2: `/registerV2`,
  FORGOT_PASSWORD: `/forgotPassword`,
  FORGOT_PASSWORD_V2: `/forgotPasswordV2`,
  RESET_PASSWORD: `/resetPassword`,
  RESET_PASSWORD_V2: `/resetPasswordV2`,
  RESET_PASSWORD_USER_MIGRATION: `/resetMigrationPassword`,

  STYLE_GUIDELINES: `/admin/v2/styleGuidelines`,
  CREATE_COMPANY: `/company/create`,

  POS_INDEX: `/pos`,
  POS_SALE: (query?: string) => `/pos/sale${query || ''}`,
  POS_PAYMENTS: `/pos/payments`,
  POS_SALE_SUCCESS: `/pos/sale/success`,
  POS_ADD_CUSTOMER: `/pos/customers/add`,
  POS_PAYMENT: (paymentId: string) => `/pos/payment/${paymentId}`,
  POS_EDIT_CUSTOMER: (customerId: string) => `/pos/customers/edit?customerId=${customerId}`,
  POS_VIEW_CUSTOMERS: `/pos/customers`,
  POS_SETTINGS: `/pos/settings`,

  DASHBOARD: (companyId: number) => `/company/${companyId}/dashboard`,
  DASHBOARD_SETTINGS: (companyId: number) => `/company/${companyId}/dashboard/settings`,
  DASHBOARD_SETTINGS_GENERAL: (companyId: number) =>
    `/company/${companyId}/dashboard/settings/general`,
  DASHBOARD_SETTINGS_LOCATIONS: (companyId: number) =>
    `/company/${companyId}/dashboard/settings/locations`,
  DASHBOARD_SETTINGS_POS: (companyId: number) => `/company/${companyId}/dashboard/settings/pos`,
  DASHBOARD_SETTINGS_RECEIPTS: (companyId: number) =>
    `/company/${companyId}/dashboard/settings/receipts`,
  DASHBOARD_SETTINGS_TAXES: (companyId: number) => `/company/${companyId}/dashboard/settings/taxes`,
  DASHBOARD_SETTINGS_INVENTORY: (companyId: number) =>
    `/company/${companyId}/dashboard/settings/inventory`,
  DASHBOARD_SETTINGS_GIFTCARDS: (companyId: number) =>
    `/company/${companyId}/dashboard/settings/giftcards`,

  DASHBOARD_PRODUCTS: (companyId: number) => `/company/${companyId}/dashboard/products`,
  DASHBOARD_PRODUCTS_LISTING: (companyId: number, query?: string) =>
    `/company/${companyId}/dashboard/products/productListing${query || ''}`,
  DASHBOARD_PRODUCTS_CATEGORIES: (companyId: number) =>
    `/company/${companyId}/dashboard/products/categories`,
  DASHBOARD_PRODUCTS_SUPPLIERS: (companyId: number) =>
    `/company/${companyId}/dashboard/products/suppliers`,
  PRINTING_CONFIG_VIEW: (
    companyId: number,
    products: Array<{ productId: string; quantity: number }> | null,
  ) => {
    let url = `/company/${companyId}/dashboard/print?`
    if (products) {
      const stringifiedJSON = encodeURIComponent(
        JSON.stringify({
          products: products,
        }),
      )
      url += `config=${stringifiedJSON}&`
    } else {
      url += `useLocalStorage=true&`
    }
    return url
  },
  EDIT_SUPPLIER: (companyId: number, supplierId: number) =>
    `/company/${companyId}/dashboard/products/suppliers/edit?supplierId=${supplierId}`,
  DASHBOARD_PRODUCTS_DISCOUNTS: (companyId: number) =>
    `/company/${companyId}/dashboard/products/discounts`,
  DASHBOARD_PRODUCTS_ADD_DISCOUNT: (companyId: number) =>
    `/company/${companyId}/dashboard/products/discounts/addDiscount`,
  DASHBOARD_PRODUCTS_EDIT_DISCOUNT: (companyId: number, discountId: number) =>
    `/company/${companyId}/dashboard/products/discounts/editDiscount/${discountId}`,
  ADD_TAX_GROUP: (companyId: number) => `/company/${companyId}/dashboard/products/taxes/add`,
  EDIT_TAX_GROUP: (companyId: number, taxGroupId: number) =>
    `/company/${companyId}/dashboard/products/taxes/edit?taxGroupId=${taxGroupId}`,
  CREATE_PRODUCT: (companyId: number, query?: string) =>
    `/company/${companyId}/dashboard/products/productListing/addProduct${query || ''}`,
  EDIT_PRODUCT: (companyId: number, productId: string, query?: string) =>
    `/company/${companyId}/dashboard/products/productListing/editProduct/${productId}${
      query || ''
    }`,
  DASHBOARD_CUSTOMERS: (companyId: number) => `/company/${companyId}/dashboard/customers/list`,
  ADD_CUSTOMER: (companyId: number) => `/company/${companyId}/dashboard/customers/list/add`,
  EDIT_CUSTOMER: (companyId: number, customerId: string) =>
    `/company/${companyId}/dashboard/customers/list/edit?customerId=${customerId}`,
  CUSTOMER_LOYALTY: (companyId: number) => `/company/${companyId}/dashboard/customers/loyalty`,
  DASHBOARD_SALES: (companyId: number, paymentId?: string) =>
    `/company/${companyId}/dashboard/sales/list?paymentId=${paymentId}`,
  DASHBOARD_SALES_LIST: (companyId: number, query: string) =>
    `/company/${companyId}/dashboard/sales/list${query || ''}`,
  DASHBOARD_EMPLOYEES: (companyId: number) => `/company/${companyId}/dashboard/employees`,
  DASHBOARD_EMPLOYEES_EMPLOYEES: (companyId: number) =>
    `/company/${companyId}/dashboard/employees/employees`,
  DASHBOARD_ADD_EMPLOYEE: (companyId: number, query?: string) =>
    `/company/${companyId}/dashboard/employees/addEmployee${query || ''}`,
  DASHBOARD_EDIT_EMPLOYEE: (companyId: number, employeeId: string) =>
    `/company/${companyId}/dashboard/employees/addEmployee?userId=${employeeId}`,
  DASHBOARD_EMPLOYEES_SECURITY_ROLES: (companyId: number) =>
    `/company/${companyId}/dashboard/employees/securityRoles`,
  ADD_SECURITY_ROLE: (companyId: number) =>
    `/company/${companyId}/dashboard/employees/securityRoles/create`,
  EDIT_SECURITY_ROLE: (companyId: number, securityRoleId: number) =>
    `/company/${companyId}/dashboard/employees/securityRoles/edit?securityRoleId=${securityRoleId}`,

  DASHBOARD_INVENTORY: (companyId: number) => `/company/${companyId}/dashboard/inventory`,
  DASHBOARD_INVENTORY_SNAPSHOTS: (companyId: number) =>
    `/company/${companyId}/dashboard/inventory/update/snapshots`,
  DASHBOARD_INVENTORY_SNAPSHOT_BY_ID: (companyId: number, inventorySnapshotId: number) =>
    `/company/${companyId}/dashboard/inventory/update/snapshots/${inventorySnapshotId}`,
  DASHBOARD_INVENTORY_TRANSFER: (companyId: number) =>
    `/company/${companyId}/dashboard/inventory/transfer`,
  DASHBOARD_INVENTORY_CONVERT: (companyId: number) =>
    `/company/${companyId}/dashboard/inventory/convert`,
  DASHBOARD_INVENTORY_BULK_UPDATE: (companyId: number, query?: string) =>
    `/company/${companyId}/dashboard/inventory/overview${query || ''}`,
  DASHBOARD_INVENTORY_BULK_TRANSFER: (companyId: number) =>
    `/company/${companyId}/dashboard/inventory/transfer`,
  ADD_INVENTORY_BULK_TRANSFER: (companyId: number) =>
    `/company/${companyId}/dashboard/inventory/transfer/editTransfer`,
  EDIT_INVENTORY_BULK_TRANSFER: (companyId: number, inventoryTransferId: string) =>
    `/company/${companyId}/dashboard/inventory/transfer/editTransfer/${inventoryTransferId}`,
  DASHBOARD_TRANSFER_TEMPLATES: (companyId: number) =>
    `/company/${companyId}/dashboard/inventory/transfer/templates`,
  CREATE_TRANSFER_TEMPLATE: (companyId: number) =>
    `/company/${companyId}/dashboard/inventory/transfer/templates/create`,
  DASHBOARD_TRANSFER_TEMPLATE_BY_ID: (companyId: number, transferTemplateId: number) =>
    `/company/${companyId}/dashboard/inventory/transfer/templates/${transferTemplateId}`,
  DASHBOARD_INVENTORY_AUDIT: (companyId: number) =>
    `/company/${companyId}/dashboard/inventory/audit`,
  EDIT_INVENTORY_AUDIT: (companyId: number, inventoryAuditId: string) =>
    `/company/${companyId}/dashboard/inventory/audit/${inventoryAuditId}`,
  DASHBOARD_CONTACT: (companyId: number) => `/company/${companyId}/dashboard/contact`,
  DASHBOARD_INVOICES: (companyId: number) => `/company/${companyId}/dashboard/purchaseOrders`,
  ADD_INVOICE: (companyId: number, query?: string) =>
    `/company/${companyId}/dashboard/purchaseOrders/editPurchaseOrder${query || ''}`,
  RETURN_INVOICE: (companyId: number) =>
    `/company/${companyId}/dashboard/purchaseOrders/editPurchaseOrder?isReturn=true`,
  EDIT_INVOICE: (companyId: number, purchaseOrderId: string) =>
    `/company/${companyId}/dashboard/purchaseOrders/editPurchaseOrder/${purchaseOrderId}`,
  PRINT_INVOICE: (companyId: number, purchaseOrderId: string) =>
    `/company/${companyId}/dashboard/purchaseOrders/editPurchaseOrder/${purchaseOrderId}/print`,

  DASHBOARD_SUMMARY_REPORTS: (companyId: number) =>
    `/company/${companyId}/dashboard/reports/analytics/summary`,
  DASHBOARD_RESTOCK_REPORTS: (companyId: number) =>
    `/company/${companyId}/dashboard/reports/analytics/restock`,
  DASHBOARD_REPORTS: (companyId: number, reportingPeriod: string) =>
    `/company/${companyId}/dashboard/reports/analytics/${reportingPeriod}`,
  DASHBOARD_PAYMENT_TYPES_REPORT: (companyId: number) =>
    `/company/${companyId}/dashboard/reports/analytics/paymentTypes`,
  DASHBOARD_ADVANCED_REPORTS: (companyId: number, reportType = '') =>
    `/company/${companyId}/dashboard/reports/advanced/${reportType}`,
  DASHBOARD_INVENTORY_LOG_REPORTS: (companyId: number) =>
    `/company/${companyId}/dashboard/reports/inventoryLogs`,
  DASHBOARD_INTEGRATIONS: (companyId: number) => `/company/${companyId}/dashboard/integrations`,
  DASHBOARD_INTEGRATIONS_LEAFLY: (companyId: number) =>
    `/company/${companyId}/dashboard/integrations/leafly`,
  DASHBOARD_INTEGRATIONS_WOOCOMMERCE: (companyId: number) =>
    `/company/${companyId}/dashboard/integrations/wooCommerce`,
  DASHBOARD_INTEGRATIONS_WOOCOMMERCE_PRODUCTS: (companyId: number) =>
    `/company/${companyId}/dashboard/integrations/wooCommerce/products`,
  DASHBOARD_INTEGRATIONS_WOOCOMMERCE_CATEGORIES: (companyId: number) =>
    `/company/${companyId}/dashboard/integrations/wooCommerce/categories`,
  DASHBOARD_INTEGRATIONS_WOOCOMMERCE_SETTINGS: (companyId: number) =>
    `/company/${companyId}/dashboard/integrations/wooCommerce/settings`,
  DASHBOARD_INTEGRATIONS_QUICKBOOKS_ONLINE_SETTINGS: (companyId: number) =>
    `/company/${companyId}/dashboard/integrations/quickBooks/main/settings`,
  DASHBOARD_INTEGRATIONS_QUICKBOOKS_ONLINE_SYNC: (companyId: number) =>
    `/company/${companyId}/dashboard/integrations/quickBooks/main/sync`,
  DASHBOARD_INTEGRATIONS_QUICKBOOKS_AUTH_CONNECT: (companyId: number) =>
    `/company/${companyId}/dashboard/integrations/quickBooks/authConnect`,
  DASHBOARD_INTEGRATIONS_WEEDMAPS: (companyId: number) =>
    `/company/${companyId}/dashboard/integrations/weedmaps`,
}
