import React, { useState } from 'react'
import { chunk } from 'lodash'
import { Button, message, Spin, Collapse, Alert } from 'antd'
import { UploadFile } from 'antd/lib/upload/interface'
import { ProvinceProductsUploadModal } from './ProvinceProductsUploadModal'
import { ProvinceProductsHeader } from './ProvinceProductsHeader'
import { Track } from '../../../Track'
import { uploadFileToS3Bucket, sleep, parseErrorMsg } from '../../../utilities/helpers'
import { ProvinceProductsList } from './ProvinceProductsList'
import { ProvinceProductsUpdated } from './ProvinceProductsUpdated'

import './ProvinceProductsImport.scss'
import { CompaniesModels } from '@getgreenline/companies'
import { provinceProductsApi, ProvinceProductModels } from '@getgreenline/products'

const provinceOptions = [
  {
    name: 'Alberta',
    value: CompaniesModels.Province.AB,
  },
  {
    name: 'British Columbia',
    value: CompaniesModels.Province.BC,
  },
  {
    name: 'Ontario & all other provinces',
    value: CompaniesModels.Province.ON,
  },
]

export function ProvinceProductsImport() {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [changeSet, setChangeSet] =
    useState<ProvinceProductModels.IProvinceProductChangeSetContract>()
  const [selectedProvince, setSelectedProvince] = useState<CompaniesModels.Province>()
  const [fileName, setFileName] = useState<string>()
  const [error, setError] = useState<string>()

  const handleModalClose = () => {
    setModalIsOpen(false)
  }

  const parseDroppedFile = (file: UploadFile) => {
    const { name, type, originFileObj } = file
    const fileExtension = name.split('.').pop()

    return {
      originFileObj,
      fullFileName: name,
      fileExtension,
      contentType: type,
    }
  }

  const handleFileDrop = async (selectedProvince: CompaniesModels.Province, file: UploadFile) => {
    handleModalClose()
    setError(undefined)

    try {
      Track.track('dashboard:admin_provinceProductImported')

      const { originFileObj, fullFileName, fileExtension, contentType } = parseDroppedFile(file)

      if (!fileExtension || fileExtension === fullFileName) {
        message.error('Invalid file extension')
        return
      }

      setIsUploading(true)

      const signedAws = await provinceProductsApi.uploadProvinceProductsCatalogue(
        selectedProvince,
        fileExtension,
        contentType,
      )

      const { signedUrl, fileName } = signedAws

      await uploadFileToS3Bucket(originFileObj, signedUrl, contentType)
      await sleep(5000)

      message.success('Catalog uploaded')

      getProvinceProductsChangeSet(selectedProvince, fileName)

      setSelectedProvince(selectedProvince)
      setFileName(fileName)
    } catch (error) {
      setIsUploading(false)
      message.error(parseErrorMsg(error) || 'An error occurred while uploading the file', 10)
    }
  }

  const getProvinceProductsChangeSet = async (
    province: CompaniesModels.Province,
    fileName: string,
  ) => {
    setIsUploading(true)

    try {
      const changeSet = await provinceProductsApi.getProvinceProductsChangeSet(province, fileName)

      setChangeSet(changeSet)
    } catch (error) {
      if (error?.response?.status === 400 && error?.response?.data?.message) {
        setError(error.response.data.message)
        return
      }

      message.error(parseErrorMsg(error) || 'An error occurred while requesting the file', 10)
    } finally {
      setIsUploading(false)
    }
  }

  const uploadProductChangeSet = async () => {
    setIsUploading(true)

    if (!selectedProvince || !changeSet) {
      return
    }

    try {
      const products = [
        ...changeSet.productsToAdd,
        ...changeSet.updatedProducts.map((p) => p.product),
      ]

      const chunks = chunk(products, 200)

      await Promise.all(
        chunks.map((chunk) => provinceProductsApi.saveProvinceProducts(selectedProvince, chunk)),
      )

      setSelectedProvince(undefined)
      setFileName(undefined)
      setChangeSet(undefined)

      message.success('Successfully uploaded change set')
    } catch (error) {
      message.error(parseErrorMsg(error) || 'An error occurred while requesting the file', 10)
    } finally {
      setIsUploading(false)
    }
  }

  const cancelUploadChangeSet = () => {
    setSelectedProvince(undefined)
    setFileName(undefined)
    setChangeSet(undefined)
  }

  const shouldShowUploadButton = !isUploading && !changeSet
  const shouldDisableConfirmUpdateButtom =
    changeSet && changeSet.productsToAdd.length === 0 && changeSet.updatedProducts.length === 0

  return (
    <>
      <ProvinceProductsHeader />
      <div className='province-products-update'>
        {modalIsOpen && (
          <ProvinceProductsUploadModal
            provinces={provinceOptions}
            onModalClose={handleModalClose}
            onFileDrop={handleFileDrop}
          />
        )}

        {error && (
          <div className='alert-error'>
            <Alert
              type='error'
              message='Error'
              description={error}
              onClose={() => setError(undefined)}
              showIcon
              closable
            />
          </div>
        )}

        {isUploading && (
          <div className='center-div'>
            <Spin />
          </div>
        )}

        {!isUploading && changeSet && (
          <div className='changeset'>
            <div className='changeset-buttons'>
              <Button
                type='primary'
                size='large'
                icon='upload'
                disabled={shouldDisableConfirmUpdateButtom}
                onClick={uploadProductChangeSet}
              >
                Confirm Change Set Import
              </Button>

              <Button type='danger' size='large' icon='reload' onClick={cancelUploadChangeSet}>
                Cancel
              </Button>
            </div>

            <h1>Province: {selectedProvince}</h1>

            <Collapse>
              <Collapse.Panel
                key='new-products'
                header={`New products (${changeSet.productsToAdd.length})`}
              >
                <ProvinceProductsList
                  title='New products'
                  productsToAdd={changeSet.productsToAdd}
                />
              </Collapse.Panel>
              <Collapse.Panel
                key='update-products'
                header={`Update Products (${changeSet.updatedProducts.length})`}
              >
                <ProvinceProductsUpdated updateProducts={changeSet.updatedProducts} />
              </Collapse.Panel>
            </Collapse>
          </div>
        )}

        {shouldShowUploadButton && (
          <div className='center-div'>
            <Button size='large' icon='import' onClick={() => setModalIsOpen(true)}>
              Import change set
            </Button>
          </div>
        )}
      </div>
    </>
  )
}
