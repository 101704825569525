import * as React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { Observer } from 'mobx-react-lite'

interface Props {
  draggableId: string
  position: number
}

export const DraggableItem = (props: React.PropsWithChildren<Props>) => {
  const { draggableId, position, children } = props

  return (
    <Draggable key={draggableId} draggableId={draggableId} index={position}>
      {(provided, snapshot) => (
        <Observer>
          {() => (
            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
              {children}
            </div>
          )}
        </Observer>
      )}
    </Draggable>
  )
}
