import {
  AuthenticationDetails,
  CognitoUserPool,
  CognitoUser,
  CognitoUserSession,
  ICognitoUserPoolData,
} from 'amazon-cognito-identity-js'
import { ErrorCodes } from '../constants/ErrorCodes'
import { getErrorMessage } from './errors'
import { LocalStorage } from './LocalStorage'

export interface ICognitoError extends Error {
  /** https://docs.aws.amazon.com/cognito/latest/developerguide/cognito-user-pool-managing-errors.html */
  code: string
  /** https://docs.aws.amazon.com/cognito/latest/developerguide/cognito-user-pool-managing-errors.html */
  name: string
}

const createUserPool = (cognitoUserPoolOption: ICognitoUserPoolData) => {
  try {
    return new CognitoUserPool(cognitoUserPoolOption)
  } catch (error) {
    console.warn('[COGNITO]', error)
  }
}

const storedApiUrl = LocalStorage.getOverrideAPIUrl()
const isFeatureBranch = `${storedApiUrl}`.includes('-api.staging-getgreenline.co')

const userPoolId = `${process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID}`

const clientId = `${process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID}`

const cognitoUserPool = createUserPool({
  UserPoolId: userPoolId,
  ClientId: clientId,
})

/** Creates Cognito User object from email */
export const createUser = (email: string) => {
  if (!cognitoUserPool)
    throw new Error('User Pool is not created. Check your ENV keys and restart server.')
  return new CognitoUser({ Username: email, Pool: cognitoUserPool })
}

/** Retrieves current authenticated Cognito User */
export const getCurrentUser = () => {
  if (!cognitoUserPool)
    throw new Error('User Pool is not created. Check your ENV keys and restart server.')
  return cognitoUserPool.getCurrentUser()
}

export const isCognitoUser = !!cognitoUserPool?.getCurrentUser()

export const getAuthenticationDetails = (email: string, password: string) =>
  new AuthenticationDetails({
    Username: email,
    Password: password,
  })

export const resendConfirmationCode = (cognitoUser: CognitoUser) =>
  new Promise((resolve, reject) => {
    cognitoUser.resendConfirmationCode((error, result) => {
      if (error) reject(error)
      else resolve(result)
    })
  })

/** Updates user password for users created in AWS cognito console requiring new passwords */
export const completeNewPasswordChallenge = async ({
  cognitoUser,
  newPassword,
  userAttributes,
}: {
  cognitoUser: CognitoUser
  newPassword?: string
  userAttributes?: unknown
}): Promise<CognitoUserSession> =>
  new Promise((resolve, reject) => {
    if (!newPassword) return reject('New password is required')
    cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes || {}, {
      onSuccess: resolve,
      onFailure: reject,
    })
  })

/** Authenticates a user in Cognito and saves session to local storage to be retrievable with `getCurrentUser()`,
 * or completes new password challenge if user is required to change password
 */
export const authenticateUser = async (
  cognitoUser: CognitoUser,
  authenticationDetails: AuthenticationDetails,
  newPassword?: string,
): Promise<CognitoUserSession> =>
  new Promise((resolve, reject) => {
    cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH')
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: resolve,
      onFailure: reject,
      newPasswordRequired: (userAttributes) => {
        if (userAttributes?.email_verified) delete userAttributes.email_verified
        if (userAttributes?.email) delete userAttributes.email
        resolve(completeNewPasswordChallenge({ cognitoUser, newPassword, userAttributes }))
      },
    })
  })

/** Updates user password using verification code from `sendForgotPasswordEmail()` */
export const resetPassword = async ({
  cognitoUser,
  verificationCode,
  newPassword,
}: {
  cognitoUser: CognitoUser
  verificationCode: string
  newPassword: string
}): Promise<string> =>
  new Promise((resolve, reject) => {
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: resolve,
      onFailure: reject,
    })
  })

/** Sends verification code to user's email to use for `resetPassword()` */
export const sendForgotPasswordEmail = async (cognitoUser: CognitoUser): Promise<unknown> =>
  new Promise((resolve, reject) => {
    cognitoUser.forgotPassword({ onSuccess: resolve, onFailure: reject })
  })

export const getCognitoUserSession = async (
  cognitoUser: CognitoUser,
): Promise<CognitoUserSession> => {
  return new Promise((resolve, reject) => {
    cognitoUser.getSession((error: Error | null, session: CognitoUserSession | null) => {
      if (error || !session) {
        return reject(error || "Session doesn't exist")
      }
      resolve(session)
    })
  })
}

export enum CognitoErrorTypes {
  USER_NOT_FOUND = 'UserNotFoundException',
  USER_NOT_CONFIRMED = 'UserNotConfirmedException',
  INVALID_PASSWORD = 'NotAuthorizedException',
  INVALID_CODE = 'CodeMismatchException',
  EXPIRED_CODE = 'ExpiredCodeException',
  INVALID_EMAIL = 'InvalidParameterException',
  INVALID_USERNAME = 'InvalidParameterException',
  TOO_MANY_REQUESTS = 'TooManyRequestsException',
}

export const parseCongitoError = (error: unknown) => {
  const message = getErrorMessage(error)
  switch (message) {
    case 'User password cannot be reset in the current state.':
      return 'Cannot reset password before verifying new account. Please check your inbox for a verification link or contact your administrator if the email was lost or expired to send again.'
    case 'Attempt limit exceeded, please try after some time.':
      return "You've tried to reset this password too many times. Please wait and try again later."
    case `Exception migrating user in app client ${process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID}`:
      return isFeatureBranch
        ? `Feature branches cannot migrate users to cognito (${ErrorCodes.AUTH_MIGRATION_ERROR})`
        : `Please contact support. (${ErrorCodes.AUTH_MIGRATION_ERROR})`
    default:
      return (
        message ||
        'Something went wrong. Please try again later or contact support if the problem persists.'
      )
  }
}
