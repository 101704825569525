import * as React from 'react'
import { observer, Provider } from 'mobx-react'
import { LoadingAnimation } from '../../components/LoadingAnimation'
import { CurrentCompanyStore } from '../../stores/CurrentCompanyStore'
import { CurrentUserStore } from '../../stores/CurrentUserStore'
import { setupInterceptor } from '../../utilities/auth'

@observer
export class AdminRoot extends React.Component<{}, {}> {
  currentUserStore = new CurrentUserStore()
  currentCompanyStore = new CurrentCompanyStore()

  componentDidMount = async () => {
    const authToken = window.localStorage.getItem('authToken')
    if (authToken !== null) {
      try {
        await setupInterceptor()
        await this.currentUserStore.getCurrentHumanUser()
      } catch (error) {
        window.location.href = '/'
      }
    }
  }

  render() {
    const user = this.currentUserStore.currentUser

    if (!user) {
      return (
        <div className='full-height'>
          <div className='container'>
            <LoadingAnimation />
          </div>
        </div>
      )
    } else if (!user.isAdmin) {
      return (
        <div className='full-height'>
          <div className='container'>You do not have permissions to access this page</div>
        </div>
      )
    } else {
      return (
        <Provider currentUserStore={this.currentUserStore}>
          <div>{this.props.children}</div>
        </Provider>
      )
    }
  }
}
