import { SelectProps } from 'antd-v4/lib/select'
import { Select, Spin, Modal } from 'antd-v4'
import React, { useState, useMemo, useRef, useEffect } from 'react'

import debounce from 'lodash/debounce'

import { CurrentUserStore } from '../../../stores/CurrentUserStore'
import { axios } from '@getgreenline/shared'
import { IUser } from '@getgreenline/events/build/domains/users'

interface Props {
  currentUserStore?: CurrentUserStore
}

interface State {
  user?: {
    label: string
    value: string
  }
  users: IUser[]
  modalOpen: boolean
  errorMessage?: string
  successMessage?: string
  email?: string | null
  userId?: string
}

export interface DebounceSelectProps<ValueType>
  extends Omit<SelectProps<ValueType>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>
  debounceTimeout?: number
}

function DebounceSelect<ValueType extends { label: React.ReactNode; value: string | number }>({
  fetchOptions,
  debounceTimeout = 800,
  ...props
}: DebounceSelectProps<ValueType>) {
  const [fetching, setFetching] = useState(true)
  const [options, setOptions] = useState<ValueType[]>([])
  const fetchRef = useRef(0)

  useEffect(() => {
    fetchOptions('').then((options) => {
      setOptions(options)
      setFetching(false)
    })
  }, [])

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return
        }

        setOptions(newOptions)
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])

  return (
    <Select<ValueType>
      showSearch
      labelInValue
      allowClear={true}
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size='small' /> : null}
      {...props}
      options={options}
    />
  )
}

export class ModifyUsers extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      modalOpen: false,
      user: undefined,
      errorMessage: undefined,
      successMessage: undefined,
      users: [],
    }
  }

  fetchUsers = async (search: string) => {
    console.log(search)
    const { data } = await axios.get('/api/v1/admin/users', {
      params: {
        offset: 0,
        limit: 10,
        searchQuery: search,
      },
    })
    this.setState({ users: data.data })

    return data.data.map((user: IUser) => ({
      label: user.email,
      value: user.id,
    }))
  }

  updateUserEmail = async () => {
    const { userId, email } = this.state
    await axios.patch(`api/v1/admin/users/${userId}`, { email: email || null })
    this.closeModal()
  }

  closeModal = () => {
    this.setState({
      modalOpen: false,
      user: undefined,
      errorMessage: undefined,
      successMessage: undefined,
    })
  }

  render() {
    const { modalOpen, user, users, email } = this.state
    const currentUser = users.find((u) => u.id === user?.value)

    return (
      <div className='background-shadow p-4'>
        <h5 className='text-muted'>Modify users</h5>

        <label htmlFor='selectUser'>Select a user</label>
        <DebounceSelect
          value={user}
          id='selectUser'
          placeholder='Select a user'
          fetchOptions={this.fetchUsers}
          onChange={(value) =>
            this.setState({
              user: value,
              modalOpen: true,
              userId: value.value,
              email: value.label,
            })
          }
          style={{ width: '100%', marginBottom: '10px' }}
        />

        {currentUser && (
          <Modal
            visible={modalOpen}
            title={`Edit user`}
            okText='Save user'
            onOk={() => this.updateUserEmail()}
            onCancel={this.closeModal}
          >
            <div className='mb-5'>
              <label className='font-weight-normal primary-font-black'>
                Name <span className='text-danger'>* required</span>
              </label>
              <input
                disabled
                className='form-control input-width'
                value={name || currentUser.name}
              />
            </div>

            <div className='mb-5'>
              <label className='font-weight-normal primary-font-black'>Dashboard email</label>
              <div className='text-muted mb-2 primary-font-size'>
                Required to log into the dashboard
              </div>
              <input
                className='form-control input-width'
                value={email || undefined}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
            </div>
          </Modal>
        )}
      </div>
    )
  }
}
