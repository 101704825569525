import * as Sentry from '@sentry/react'
import { CaptureContext } from '@sentry/types'

// https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/context/
class SentryWrapper {
  captureException = (message: string, context?: CaptureContext) => {
    Sentry.captureException(new Error(message), context)
  }
}

export function captureError(message: string, error: any, notifyUser = true, timeout?: number) {
  sentryWrapper.captureException(error)
}

export const sentryWrapper = new SentryWrapper()
