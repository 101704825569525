import { ILocation } from '@getgreenline/homi-shared'
import { LocationModels } from '@getgreenline/locations'
import { Checkbox, Tabs, Menu, Input, List, Button } from 'antd-v4'
import { Icon, Dropdown, Drawer, Select } from 'antd'
import React from 'react'
import {
  CannabinoidMetaDataItem,
  CreateChildProduct,
  CreateProduct,
  ProductMetaDataItem,
  MetaDataReservedKeys,
} from '../ProductStore'
import { LocationsSelect } from './LocationsSelect'
import { CannabinoidInputFields } from './CannabinoidInputFields'
import { MetaDataModels } from '@getgreenline/products'
import './CannabinoidTerpeneDrawer.scss'
import { observer } from 'mobx-react'
import { CurrentCompanyStore } from '../../../../../stores/CurrentCompanyStore'

interface Props {
  isOpen: boolean
  initialTab: string
  index: number
  disabled: boolean
  product: CreateProduct | CreateChildProduct
  cannabinoid: CannabinoidMetaDataItem
  orderedPermittedLocations?: ILocation[]
  mappedGlobalLocations?: Map<number, LocationModels.IGlobalLocationContract>
  onClose: () => void
  currentCompanyStore?: CurrentCompanyStore
  availableBatchNumbers?: string[]
  loadBatches?: () => void
}
interface State {
  tabKey: string
  selectedTerpenes: string[]
  selectedCannabinoids: string[]
  openDropdown: boolean
  terpeneSearch: string
  cannabinoidSearch: string
  cannabinoidToggle: boolean
}

enum EnumTypes {
  'CannabinoidReservedKeys',
  'TerpeneReservedKeys',
}

const thcKey = MetaDataModels.CannabinoidReservedKeys.DELTA_9_TETRAHYDROCANNABINOL_THC.replace(
  /Δ/g,
  'Delta',
)
const cbdKey = MetaDataModels.CannabinoidReservedKeys.CANNABIDIOL_CBD

@observer
export class CannabinoidTerpeneDrawer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      tabKey: this.props.initialTab,
      selectedCannabinoids: this.initializeSelectedCannabinoids(),
      selectedTerpenes: this.initializeSelectedTerpenes(),
      openDropdown: false,
      terpeneSearch: '',
      cannabinoidSearch: '',
      cannabinoidToggle: true,
    }
  }

  getMinCannabinoid = (cannabinoid: string, cannabinoidMap: Map<string, ProductMetaDataItem>) => {
    switch (cannabinoid) {
      case `${MetaDataModels.CannabinoidReservedKeys.CANNABIDIOL_CBD}`: {
        return cannabinoidMap.get(`${MetaDataReservedKeys.MIN_CBD}`)
      }
      case this.removeDeltaSymbol(
        `${MetaDataModels.CannabinoidReservedKeys.DELTA_9_TETRAHYDROCANNABINOL_THC}`,
      ): {
        return cannabinoidMap.get(`${MetaDataReservedKeys.MIN_THC}`)
      }
      default: {
        return cannabinoidMap.get(`min${cannabinoid}`)
      }
    }
  }

  getMaxCannabinoid = (cannabinoid: string, cannabinoidMap: Map<string, ProductMetaDataItem>) => {
    switch (cannabinoid) {
      case `${MetaDataModels.CannabinoidReservedKeys.CANNABIDIOL_CBD}`: {
        return cannabinoidMap.get(`${MetaDataReservedKeys.MAX_CBD}`)
      }
      case this.removeDeltaSymbol(
        `${MetaDataModels.CannabinoidReservedKeys.DELTA_9_TETRAHYDROCANNABINOL_THC}`,
      ): {
        return cannabinoidMap.get(`${MetaDataReservedKeys.MAX_THC}`)
      }
      default: {
        return cannabinoidMap.get(`max${cannabinoid}`)
      }
    }
  }

  updateCannabinoidMetaData = () => {
    const cannabinoidMap = new Map<string, ProductMetaDataItem>()

    for (const cnb of this.props.cannabinoid.metaData) {
      cannabinoidMap.set(cnb.metaKey, cnb)
    }

    this.state.selectedCannabinoids.forEach((cannab) => {
      const minCannab = this.getMinCannabinoid(cannab, cannabinoidMap)
      const maxCannab = this.getMaxCannabinoid(cannab, cannabinoidMap)

      if (minCannab?.metaValue && maxCannab?.metaValue === null) {
        // min set, max not set
        maxCannab.setValue(minCannab.metaValue)
      }
      if (
        minCannab?.metaValue &&
        maxCannab?.metaValue &&
        parseInt(minCannab.metaValue) > parseInt(maxCannab.metaValue)
      ) {
        // min greater than max
        maxCannab.setValue(minCannab.metaValue)
      }

      if (minCannab?.metaValue === null && maxCannab?.metaValue !== null) {
        // max defined, min not defined
        minCannab.setValue('0')
      }
    })

    cannabinoidMap.clear()
    this.props.onClose()
  }

  toggleMinMax = () => {
    this.props.cannabinoid.toggleShowMaxMin()
    this.clearSelected()
  }

  initializeSelectedCannabinoids = () => {
    const cannabinoidReservedKeys = Object.keys(MetaDataModels.CannabinoidReservedKeys)
    const cannabinoidReservedValuesWithoutDeltaSymbol = cannabinoidReservedKeys.map((key) =>
      this.removeDeltaSymbol(MetaDataModels.CannabinoidReservedKeys[key]),
    )
    const cannabinoidMetaDataKeys = this.props.cannabinoid.metaData.map(
      (metaData) => metaData.metaKey,
    )

    const selectedCannabinoids = cannabinoidReservedValuesWithoutDeltaSymbol.filter(
      (key) => cannabinoidMetaDataKeys.includes(key) && !this.isCbdOrThc(key),
    )

    return [thcKey, cbdKey].concat(selectedCannabinoids)
  }

  initializeSelectedTerpenes = () => {
    const terpeneReservedKeys = Object.keys(MetaDataModels.TerpeneReservedKeys)
    const terpeneReservedValues = terpeneReservedKeys.map(
      (key) => MetaDataModels.TerpeneReservedKeys[key],
    )
    const cannabinoidMetaDataKeys = this.props.cannabinoid.metaData.map(
      (metaData) => metaData.metaKey,
    )
    return terpeneReservedValues.filter((key) => cannabinoidMetaDataKeys.includes(key))
  }

  cannabinoidSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ cannabinoidSearch: e.target.value })
  }

  terpeneSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ terpeneSearch: e.target.value })
  }

  handleChecked = (selectedProperty: string, selectedList: string[]) => {
    return !!selectedList.find((key) => key.includes(selectedProperty))
  }

  handleOpenChange = (flag: boolean) => {
    this.setState({ openDropdown: flag })
  }

  setValue = (prefix: string, value: string | number | boolean | null, cannabinoidName: string) => {
    const metaDataItem = this.getMetaData(prefix, cannabinoidName)
    const val = value?.toString() || null

    if (metaDataItem && !this.isCbdOrThc(cannabinoidName)) {
      this.props.cannabinoid.setValue(`${prefix}${cannabinoidName}`, val)
    } else if (cannabinoidName === thcKey) {
      const key = prefix + this.convertCbdOrThc(MetaDataReservedKeys.THC, prefix)

      this.props.cannabinoid.setValue(key, value)
    } else if (cannabinoidName === cbdKey) {
      const key = prefix + this.convertCbdOrThc(MetaDataReservedKeys.CBD, prefix)

      this.props.cannabinoid.setValue(key, value)
    }

    this.setState({ cannabinoidToggle: !this.state.cannabinoidToggle })
  }

  removeDeltaSymbol = (name: string) => {
    return name.replace(/Δ/g, 'Delta')
  }

  addDeltaSymbol = (name: string) => {
    return name.replace(/Delta/g, 'Δ')
  }

  getMetaDataValue = (prefix: string, name: string) => {
    if (this.isCbdOrThc(name)) {
      name = this.convertCbdOrThc(name, prefix)
    }
    const metaValue = this.getMetaData(prefix, name)

    if (prefix === 'max' && this.getMetaData('min', name) && !metaValue?.metaValue) {
      return this.getMetaData('min', name)
    }

    if (metaValue === undefined || metaValue === null) {
      return null
    }
    return metaValue
  }

  getMetaData = (prefix: string, name: string) => {
    return this.props.cannabinoid.metaData.find((datum) => datum.metaKey === prefix + name)
  }

  removeMetaDataItems = (name: string, enumType: EnumTypes) => {
    if (enumType === EnumTypes.TerpeneReservedKeys) {
      this.removeMetaDataItem(name, '')
    } else {
      this.removeMetaDataItem(name, 'min')
      this.removeMetaDataItem(name, '')
      this.removeMetaDataItem(name, 'max')
    }
  }

  removeMetaDataItem = (name: string, prefix: string) => {
    const metaDataItem = this.getMetaData(prefix, name)
    metaDataItem && this.props.cannabinoid.removeMetaDataItem(metaDataItem)
  }

  addNewMetaDataItems = (name: string, enumType: EnumTypes) => {
    if (enumType === EnumTypes.TerpeneReservedKeys) {
      this.addNewMetaDataItem(name, '')
    } else {
      this.addNewMetaDataItem(name, 'min')
      this.addNewMetaDataItem(name, '')
      this.addNewMetaDataItem(name, 'max')
    }
  }

  addNewMetaDataItem = (name: string, prefix: string) => {
    const metaDataItem = new ProductMetaDataItem(prefix + name, null)
    metaDataItem && this.props.cannabinoid.addMetaDataItem(metaDataItem)
  }

  clearSelected = () => {
    const { cannabinoid } = this.props
    if (this.state.tabKey === 'cannabinoids') {
      // Dont remove cannabinoids THC and CBD, just reset the values
      const cannabinoidKeys = Object.values(MetaDataReservedKeys).filter((value) => {
        return value !== MetaDataReservedKeys.SHOW_MAX_MIN && value !== MetaDataReservedKeys.UNIT
      })
      cannabinoidKeys.forEach((value) => {
        this.props.cannabinoid.setValue(value, null)
      })

      this.state.selectedCannabinoids.forEach((key) => {
        cannabinoid.metaData.forEach((metaData) => {
          // checking substring instead of using includes to remove triple loop.
          // removes 'min' or 'max' and checks to see if the metaData key is a cannabinoid
          if (metaData.metaKey.substring(3) === key || metaData.metaKey === key) {
            cannabinoid.removeMetaDataItem(metaData)
          }
        })
      })
      this.setState({
        selectedCannabinoids: [thcKey, cbdKey],
      })
    } else {
      this.state.selectedTerpenes.forEach((terpene) => {
        const metaDataItem = new ProductMetaDataItem(terpene, null)
        cannabinoid.removeMetaDataItem(metaDataItem)
      })
      this.setState({ selectedTerpenes: [] })
    }
    this.props.product.changed = true
  }

  handlePropertyCheckbox = (
    selectedCannabinoid: string,
    selectedCannabinoids: string[],
    enumType: EnumTypes,
  ) => {
    const alreadyChecked = selectedCannabinoids.includes(selectedCannabinoid)

    let newPropertyIds

    if (alreadyChecked) {
      newPropertyIds = selectedCannabinoids.filter(
        (propertyKey) => propertyKey !== selectedCannabinoid,
      )
      this.removeMetaDataItems(selectedCannabinoid, enumType)
      this.props.product.changed = true
    } else {
      newPropertyIds = [...selectedCannabinoids, selectedCannabinoid]
      this.addNewMetaDataItems(selectedCannabinoid, enumType)
      this.props.product.changed = true
    }

    if (enumType === EnumTypes.CannabinoidReservedKeys) {
      this.setState({ selectedCannabinoids: newPropertyIds })
    } else if (enumType === EnumTypes.TerpeneReservedKeys) {
      this.setState({ selectedTerpenes: newPropertyIds })
    }
  }

  getSearchedValues = (enumValues: string[], searchValue: string) => {
    return enumValues.filter((name) => {
      return name.toLowerCase().includes(searchValue.toLowerCase())
    })
  }

  dropdownOverlay = (
    enumType: EnumTypes,
    enumValues: string[],
    searchValue: string,
    selectedValues: string[],
  ): JSX.Element => {
    return (
      <Menu>
        {this.getSearchedValues(enumValues, searchValue).map((property: string) => {
          return (
            <Menu.Item key={property}>
              <Checkbox
                checked={
                  selectedValues.includes(this.removeDeltaSymbol(property)) ||
                  this.isCbdOrThc(property)
                }
                onClick={() => {
                  this.handlePropertyCheckbox(
                    this.removeDeltaSymbol(property),
                    selectedValues,
                    enumType,
                  )
                }}
                disabled={this.isCbdOrThc(property)}
              >
                {property}
              </Checkbox>
            </Menu.Item>
          )
        })}
      </Menu>
    )
  }

  isCbdOrThc = (property: string) => {
    return (
      this.removeDeltaSymbol(property) === thcKey || this.removeDeltaSymbol(property) === cbdKey
    )
  }

  convertCbdOrThc = (property: string, prefix: string) => {
    let cannabinoidName: string = property
    if (property === thcKey) {
      cannabinoidName = MetaDataReservedKeys.THC
    } else if (property === cbdKey) {
      cannabinoidName = MetaDataReservedKeys.CBD
    }

    return prefix !== '' ? cannabinoidName.toUpperCase() : cannabinoidName
  }

  onSelect = (batchNumber: string) => {
    this.props.cannabinoid.setBatchNumber(batchNumber)
    this.props.product.changed = true
  }

  render() {
    const { disabled, cannabinoid, loadBatches } = this.props
    return (
      <Drawer
        onClose={this.updateCannabinoidMetaData}
        visible={this.props.isOpen}
        headerStyle={{ height: '32px' }}
        width={500}
        className='cannabinoid-drawer'
      >
        {this.props.availableBatchNumbers && loadBatches && (
          <div className='mr-2 mb-3' style={{ width: 220 }}>
            <Select<string>
              showSearch={true}
              getPopupContainer={(triggerNode: HTMLElement) =>
                triggerNode.parentNode as HTMLElement
              }
              style={{ width: '100%' }}
              maxTagCount={1}
              maxTagTextLength={13}
              optionLabelProp='label'
              disabled={disabled}
              className='w-100 ant-select-border-rounded'
              placeholder='Select lot'
              defaultValue={cannabinoid.batchNumber}
              value={cannabinoid.batchNumber}
              dropdownMatchSelectWidth={false}
              onSelect={this.onSelect}
            >
              {cannabinoid.batchNumber && (
                <Select.Option
                  key={cannabinoid.batchNumber}
                  value={cannabinoid.batchNumber}
                  label={cannabinoid.batchNumber}
                  className={'mb-3 ant-select-item-option-selected'}
                >
                  <div className='d-flex justify-content-between mr-2'>
                    <span className='mr-3'>{cannabinoid.batchNumber}</span>
                    <span>{cannabinoid.thcCbdRangeInfo}</span>
                  </div>
                </Select.Option>
              )}

              {this.props.availableBatchNumbers.map((batchNumber) => {
                return (
                  <Select.Option key={batchNumber} value={batchNumber} label={batchNumber}>
                    <div className='d-flex justify-content-between'>
                      <span className='mr-3'>{batchNumber}</span>
                    </div>
                  </Select.Option>
                )
              })}
            </Select>
          </div>
        )}
        {this.props.orderedPermittedLocations && this.props.mappedGlobalLocations && (
          <LocationsSelect
            index={this.props.index}
            disabled={disabled}
            product={this.props.product}
            cannabinoid={this.props.cannabinoid}
            orderedPermittedLocations={this.props.orderedPermittedLocations}
            mappedGlobalLocations={this.props.mappedGlobalLocations}
            dropdownMatchSelectWidth={true}
            currentCompanyStore={this.props.currentCompanyStore}
          ></LocationsSelect>
        )}

        <Tabs
          type='card'
          size='large'
          hideAdd={true}
          defaultActiveKey={this.state.tabKey}
          onChange={(value) => {
            this.setState({ tabKey: value })
          }}
        >
          <Tabs.TabPane tab='Cannabinoids' key='cannabinoids'>
            <>
              <div className='d-flex mb-4'>
                <Dropdown
                  visible={this.state.openDropdown}
                  onVisibleChange={this.handleOpenChange}
                  overlayStyle={{
                    maxHeight: '250px',
                    overflow:
                      this.getSearchedValues(
                        Object.values(MetaDataModels.CannabinoidReservedKeys),
                        this.state.cannabinoidSearch,
                      ).length === 0
                        ? 'visible'
                        : 'auto',
                  }}
                  overlay={this.dropdownOverlay(
                    EnumTypes.CannabinoidReservedKeys,
                    Object.values(MetaDataModels.CannabinoidReservedKeys),
                    this.state.cannabinoidSearch,
                    this.state.selectedCannabinoids,
                  )}
                  overlayClassName='p-1 mb-1 rounded background-white'
                >
                  <Input
                    placeholder='Search cannabinoid'
                    onChange={this.cannabinoidSearch}
                    prefix={<Icon type='search' />}
                    suffix={<Icon type='down' />}
                  />
                </Dropdown>

                <div className='d-flex justify-content-center align-items-center ml-3'>
                  <Checkbox
                    checked={cannabinoid.showMaxMin}
                    disabled={disabled}
                    onChange={this.toggleMinMax}
                  />
                  <label className='d-flex align-items-center ml-3 mr-3 mb-0'>Min/max</label>
                </div>
              </div>

              <List
                style={{ minHeight: '300px' }}
                dataSource={this.state.selectedCannabinoids}
                renderItem={(cannabinoidKey) => (
                  <>
                    <List.Item>{this.addDeltaSymbol(cannabinoidKey)}</List.Item>
                    <CannabinoidInputFields
                      showMaxMin={cannabinoid.showMaxMin}
                      disabled={disabled}
                      className='mr-2 mb-3 p-1'
                      onChange={this.setValue}
                      min={this.getMetaDataValue('min', cannabinoidKey)?.metaValue}
                      max={this.getMetaDataValue('max', cannabinoidKey)?.metaValue}
                      cannabinoid={this.getMetaDataValue('', cannabinoidKey)?.metaValue}
                      product={this.props.product}
                      cannabinoidName={cannabinoidKey}
                    />
                  </>
                )}
              ></List>
              <div className='p-3'></div>
            </>
          </Tabs.TabPane>
          <Tabs.TabPane tab='Terpenes' key='terpenes'>
            <div>
              <Dropdown
                visible={this.state.openDropdown}
                onVisibleChange={this.handleOpenChange}
                overlayStyle={{
                  maxHeight: '250px',
                  overflow:
                    this.getSearchedValues(
                      Object.values(MetaDataModels.TerpeneReservedKeys),
                      this.state.terpeneSearch,
                    ).length === 0
                      ? 'visible'
                      : 'auto',
                }}
                overlay={this.dropdownOverlay(
                  EnumTypes.TerpeneReservedKeys,
                  Object.values(MetaDataModels.TerpeneReservedKeys),
                  this.state.terpeneSearch,
                  this.state.selectedTerpenes,
                )}
                overlayClassName='m-1 p-1 rounded background-white'
              >
                <Input
                  placeholder='Search terpenes'
                  onChange={this.terpeneSearch}
                  prefix={<Icon type='search' />}
                  suffix={<Icon type='down' />}
                />
              </Dropdown>

              <List
                style={{ minHeight: '300px', marginBottom: '1.8rem' }}
                dataSource={this.state.selectedTerpenes}
                renderItem={(item) => <List.Item>{item}</List.Item>}
              ></List>
            </div>
          </Tabs.TabPane>
        </Tabs>
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e8e8e8',
            padding: '10px 16px',
            textAlign: 'left',
            left: 0,
            background: '#fff',
            borderRadius: '0 0 4px 4px',
          }}
        >
          <Button onClick={this.clearSelected} className='absolute align-self-end'>
            Clear all
          </Button>
        </div>
      </Drawer>
    )
  }
}
