import React from 'react'
import { observer } from 'mobx-react'
import { CreateProduct, CreateChildProduct, MetaDataReservedKeys } from '../ProductStore'
import { Checkbox, Select } from 'antd'
import { SelectValue } from 'antd/lib/select'
import { MultiLocationCannabinoids } from './MultiLocationCannabinoids'
import { ThcCbdInputFields } from './ThcCbdInputFields'

const { Option } = Select

interface Props {
  product: CreateProduct | CreateChildProduct
}

export enum CBD_THC_UNIT {
  PERCENT = '%',
  MG = 'mg',
  ML = 'ml',
  MG_G = 'mg/g',
  MG_ML = 'mg/mL',
}

@observer
export class Cannabinoids extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
  }

  get isDeleted() {
    const { product } = this.props

    return product instanceof CreateProduct ? product.isDeleted : product.parentProduct.isDeleted
  }

  render() {
    const { product } = this.props
    const { metaData } = product

    return (
      <div>
        <div className='form-group'>
          <label style={{ fontWeight: 'bold' }}>Default cannabinoids</label>
          <div className='d-flex flex-row'>
            <div className='mr-2'>
              <label className='text-bold'>Unit</label>
              <Select
                defaultValue={metaData.unit || CBD_THC_UNIT.PERCENT}
                style={{ minWidth: '100%' }}
                disabled={this.isDeleted}
                onChange={(value: SelectValue) => {
                  if (typeof value === 'string') {
                    metaData.setValue(MetaDataReservedKeys.UNIT, value)

                    product.cannabinoidMetaData.cannabinoids.forEach((cnb) => {
                      cnb.setValue(MetaDataReservedKeys.UNIT, value)
                    })
                  }
                  product.changed = true
                }}
              >
                {Object.keys(CBD_THC_UNIT).map((key) => {
                  return (
                    <Option key={key} value={`${CBD_THC_UNIT[key]}`}>
                      {CBD_THC_UNIT[key]}
                    </Option>
                  )
                })}
              </Select>
            </div>

            <div className='mr-2'>
              <label>Min/max</label>
              <div>
                <div className='d-flex justify-content-center align-items-center'>
                  <Checkbox
                    disabled={this.isDeleted}
                    checked={metaData.showMaxMin}
                    onChange={metaData.toggleShowMaxMin}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <ThcCbdInputFields
          className='mb-3 mr-2'
          showMaxMin={metaData.showMaxMin}
          disabled={this.isDeleted}
          onChange={metaData.setValue}
          minTHC={metaData.minTHC}
          thc={metaData.thc}
          maxTHC={metaData.maxTHC}
          minCBD={metaData.minCBD}
          cbd={metaData.cbd}
          maxCBD={metaData.maxCBD}
          product={product}
        />
        <MultiLocationCannabinoids product={this.props.product} disabled={this.isDeleted} />
      </div>
    )
  }
}
