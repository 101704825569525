import * as React from 'react'
import { Routes } from '../../../constants/Routes'
import { Form, Input, Icon, Button, message, Row, Col, Select } from 'antd'
import { API, IUserResponse, IUserEntityAssignment } from '@getgreenline/homi-shared'
import { FormComponentProps } from 'antd/lib/form'
import { useState, useEffect } from 'react'
import { HelpLinkMenu } from '../../../components/GrDropdown/HelpLinkMenu/HelpLinkMenu'
import * as auth from '../../../utilities/auth'
import { setRootAuthToken } from '../../../utilities/environment'
import { browserHistory } from 'react-router'
import { LocalStorage } from '../../../utilities/LocalStorage'
import { parseCongitoError } from '../../../utilities/cognito'

const EMAIL = 'email'
const PASSWORD = 'password'

const redirectToCompanyDashboard = async (companyId: number) => {
  const userAuthToken = await auth.getTokenV2()

  if (userAuthToken) {
    setRootAuthToken(userAuthToken)
    await LocalStorage.setAuthToken(userAuthToken)
  }
  window.location.href = Routes.DASHBOARD(companyId)
}

export const LoginFormDashboard = ({ form }: FormComponentProps) => {
  const { getFieldDecorator } = form

  const [isDashboardLoading, setIsDashboardLoading] = useState(false)
  const [companyId, setCompanyId] = useState<number>()
  const [showCompanySelector, setShowCompanySelector] = useState(false)
  const [companies, setCompanies] = useState<IUserEntityAssignment[]>([])
  const [currentUser, setCurrentUser] = useState<IUserResponse>()

  const checkExistingSession = async () => {
    const userAuthToken = await auth.getTokenV2()
    const userAttemptedMigration = await LocalStorage.getUserMigratingToCognito()
    if (userAttemptedMigration) {
      await auth.signOut()
      await LocalStorage.clearAuthToken()
      await LocalStorage.clearUserMigrationToCognito()
      return
    }

    if (userAuthToken) {
      setRootAuthToken(userAuthToken)
      await LocalStorage.setAuthToken(userAuthToken)

      const user = await API.getMe()
      setCurrentUser(user)
      const companies = await auth.getAllUserCompanies(user.entityAssignments)

      switch (true) {
        case companies.length === 1:
          setCompanyId(companies[0].entity.id)
          break
        case companies.length > 1:
          setCompanies(companies)
          break
        case user.entityAssignments.length > 0:
          const location = user.entityAssignments.find(
            (entity) => entity.entity.entityType === 'location',
          )
          const companyId = location?.entity.parentEntityId
          companyId && setCompanyId(companyId)
          break
        default:
          throw new Error('No valid entity assignment found')
      }
    }
  }

  useEffect(() => {
    checkExistingSession()
  }, [])

  const login = async (email: string, password: string) => {
    const lowerCaseEmail = email.toLowerCase()
    setIsDashboardLoading(true)
    try {
      const user = await auth.loginV2(lowerCaseEmail, password)
      if (!user) {
        if (window.confirm('Weak password detected. Please reset it')) {
          LocalStorage.setUnauthenticatedEmail(lowerCaseEmail)
          browserHistory.push(Routes.RESET_PASSWORD_USER_MIGRATION)
        } else {
          setIsDashboardLoading(false)
          await auth.signOut()
          await LocalStorage.clearAuthToken()
        }
      }

      if (user) {
        const companies = await auth.getAllUserCompanies(user.entityAssignments)

        switch (true) {
          case companies.length === 1:
            redirectToCompanyDashboard(companies[0].entity.id)
            break
          case companies.length > 1:
            setCompanies(companies)
            setIsDashboardLoading(false)
            setShowCompanySelector(true)
            break
          case user.entityAssignments.length > 0:
            const location = user.entityAssignments.find(
              (entity) => entity.entity.entityType === 'location',
            )
            const companyId = location?.entity.parentEntityId
            companyId && redirectToCompanyDashboard(companyId)
            break
          default:
            throw new Error('No valid entity assignment found')
        }
      }
    } catch (error) {
      setIsDashboardLoading(false)
      message.error(parseCongitoError(error))
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    form.validateFields((err) => {
      // Error states already handled here
      if (!err) {
        const emailInput: string = form.getFieldValue(EMAIL)
        const passwordInput: string = form.getFieldValue(PASSWORD)
        login(emailInput, passwordInput)
      }
    })
  }

  const onContinueActiveSession = () => {
    if (companies.length > 0) {
      setShowCompanySelector(true)

      return
    }

    if (companyId) {
      redirectToCompanyDashboard(companyId)
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      {currentUser && (companyId || companies.length > 0) && !showCompanySelector && (
        <Form.Item>
          <Button
            size='large'
            style={{
              color: 'rgba(0,0,0,.65)',
              borderColor: '#d9d9d9',
            }}
            onClick={() => onContinueActiveSession()}
            className='continue-session-btn'
          >
            <Icon type='user' />
            <span className='mr-4'>{currentUser.email}</span>
            <div>
              <em className='small'>continue active session</em>
            </div>
          </Button>
        </Form.Item>
      )}
      {showCompanySelector ? (
        <>
          <Select<number>
            style={{ marginBottom: '1rem' }}
            onChange={(value) => redirectToCompanyDashboard(value)}
            placeholder='Select a company'
          >
            {companies.map((company) => (
              <Select.Option key={company.entity.id} value={company.entity.id}>
                {company.entity.name}
              </Select.Option>
            ))}
          </Select>

          <Button onClick={() => setShowCompanySelector(false)}>Cancel</Button>
        </>
      ) : (
        <>
          <Form.Item style={{ marginBottom: '1rem' }}>
            {getFieldDecorator(EMAIL, {
              validateTrigger: 'onBlur',
              rules: [{ required: true, message: 'Please enter an email' }],
            })(
              <Input
                size='large'
                prefix={<Icon type='mail' style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder='Email'
              />,
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator(PASSWORD, {
              rules: [{ required: true, message: 'Please enter your password' }],
            })(
              <Input.Password
                size='large'
                prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder='Password'
              />,
            )}
          </Form.Item>
          <Form.Item>
            <Row type='flex' justify='space-between'>
              <Col>
                <HelpLinkMenu
                  helpLinkMenus={[
                    {
                      href: 'https://intercom.help/greenlinepos/en/articles/2075599-logging-into-the-dashboard',
                      title: 'Dashboard log-in',
                    },
                    {
                      href: 'https://intercom.help/greenlinepos/en/articles/4555024-pos-tablet-start-a-shift-and-employee-login',
                      title: 'Starting a shift',
                    },
                    {
                      href: 'https://intercom.help/greenlinepos/en/articles/4555238-pos-tablet-how-to-end-a-shift',
                      title: 'Ending a shift',
                    },
                  ]}
                />
                <Button className='mx-2' href={Routes.FORGOT_PASSWORD_V2} type='link'>
                  Forgot password
                </Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  htmlType='submit'
                  disabled={isDashboardLoading}
                  loading={isDashboardLoading}
                >
                  Log in
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </>
      )}
    </Form>
  )
}

export const WrappedLoginFormDashboard = Form.create()(LoginFormDashboard)
