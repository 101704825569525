import * as React from 'react'
import './styles.scss'

export interface AppViewProps {
  column?: boolean
  gray?: boolean
  children?: React.ReactNode
}

export const AppView: React.SFC<AppViewProps> = (props: AppViewProps) => (
  <div className={`app-view ${props.column && 'column'} ${props.gray && 'bg-light'}`}>
    {props.children}
  </div>
)

interface AppViewWrapperProps {
  children?: React.ReactNode
}

export const AppViewWrapper: React.SFC = (props: AppViewWrapperProps) => (
  <div className='app-view-wrapper'>{props.children}</div>
)

export interface AppMainViewProps {
  id?: string
  innerRef?: React.Ref<any>
  className?: string
  children?: React.ReactNode
  style?: React.CSSProperties
}

export const AppMainView = (props: AppMainViewProps) => {
  return (
    <div ref={props.innerRef} id='app-main-view' className={props.className} style={props.style}>
      {props.children}
    </div>
  )
}
