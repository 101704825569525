import * as React from 'react'
import Button, { ButtonProps } from 'antd/lib/button'
import { Tooltip, Icon } from 'antd'
import { ThemeType } from 'antd/lib/icon'

export type TBlazeButtonType =
  | 'retail'
  | 'dispatch'
  | 'blazepay'
  | 'manufacturing'
  | 'grow'
  | 'distro'
  | 'danger'
  | 'black'

interface Props {
  blazeButtonType?: TBlazeButtonType
  buttonProps: ButtonProps
  titleProps?: {
    title: string | JSX.Element
    widthBreakPoint?: number
  }
  iconProps?: {
    icon: string
    theme?: ThemeType
  }
}

export const BlazeButton = (props: Props) => {
  const { buttonProps, titleProps, iconProps } = props

  const addBlzButtonTypeClassName = (blazeButtonType?: TBlazeButtonType) => {
    if (!blazeButtonType) {
      return buttonProps.className
    }

    let buttonClassName = `blz-btn-${props.blazeButtonType} ${buttonProps.className || ''}`

    if (buttonProps.type === 'ghost') {
      buttonClassName += ` ant-btn-background-ghost`
    }

    return buttonClassName.trim()
  }

  const [width, setWidth] = React.useState(window.innerWidth)

  React.useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth))

    return function cleanup() {
      window.removeEventListener('resize', () => setWidth(window.innerWidth))
    }
  }, [])

  const renderButtonWithBreakPoint = (
    title: string | JSX.Element,
    widthBreakPoint: number,
    icon?: string,
    theme?: ThemeType,
  ): JSX.Element => {
    return width <= widthBreakPoint ? (
      <Tooltip title={title} placement='bottom'>
        <Button {...buttonProps} className={addBlzButtonTypeClassName(props.blazeButtonType)} />
      </Tooltip>
    ) : (
      <Button {...buttonProps} className={addBlzButtonTypeClassName(props.blazeButtonType)}>
        {icon && <Icon type={icon} theme={theme || 'outlined'} />}
        {title}
      </Button>
    )
  }

  return (
    <>
      {titleProps && titleProps.widthBreakPoint ? (
        renderButtonWithBreakPoint(
          titleProps.title,
          titleProps.widthBreakPoint,
          iconProps?.icon,
          iconProps?.theme,
        )
      ) : (
        <Button {...buttonProps} className={addBlzButtonTypeClassName(props.blazeButtonType)}>
          {iconProps && <Icon type={iconProps.icon} theme={iconProps.theme || 'outlined'} />}
          {titleProps && titleProps.title}
        </Button>
      )}
    </>
  )
}
