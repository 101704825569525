import * as React from 'react'
import { Descriptions, InputNumber } from 'antd'
import { acceptableDenominations, ICashCount } from '@getgreenline/homi-shared'
import { GrPrice } from '../../../utilities/helpers'

interface Props {
  disabled?: boolean
  cashCount: ICashCount
  onInputNumberChange?: (deno: number, value?: number | string | null) => void
}

export const CashCounterTable = (props: Props) => {
  const { disabled, cashCount, onInputNumberChange } = props

  const coinDenominations = acceptableDenominations.filter((deno) => deno <= 200)
  const billDenominations = acceptableDenominations.filter((deno) => deno > 200)

  const formatter = (value?: string | number) => `${value}`.replace(/\D/g, '')

  const parser = (value?: string) => (value || '').replace(/\$\s?|(,*)/g, '')

  return (
    <>
      {['Coins', 'Bills'].map((section, sectionIndex) => {
        const denomination = section === 'Coins' ? coinDenominations : billDenominations

        return (
          <Descriptions bordered key={sectionIndex} title={section} column={2} className='mb-4'>
            {denomination.map((deno, index) => (
              <Descriptions.Item key={index} label={GrPrice.convertCentToDollar(deno)}>
                {disabled ? (
                  <span>{cashCount[deno] || '-'}</span>
                ) : (
                  <InputNumber
                    type='number'
                    min={0}
                    step={1}
                    // Only allow positive integer
                    formatter={formatter}
                    parser={parser}
                    value={cashCount[deno] || undefined}
                    onChange={(value?: number | string | null) => {
                      if (onInputNumberChange) {
                        onInputNumberChange(deno, value)
                      }
                    }}
                  />
                )}
              </Descriptions.Item>
            ))}
          </Descriptions>
        )
      })}
    </>
  )
}
