import * as React from 'react'
import { Modal, message, Checkbox } from 'antd'
import { inject } from 'mobx-react'
import { POSStore } from '../../../stores/POSStore'
import { API, IShift } from '@getgreenline/homi-shared'
import { parseErrorMsg, printHTML } from '../../../utilities/helpers'
import { ShiftReceipt } from './ShiftReceipt'
import { BlazeButton } from '../../../components/CustomButtons/BlazeButton'

interface Props {
  visible: boolean
  lastShiftId: number
  setIsReceiptModalVisible: (visible: boolean) => void
}

interface InjectedProps {
  posStore?: POSStore
}

export const ReceiptModal = inject('posStore')((props: Props & InjectedProps) => {
  const { visible, lastShiftId, setIsReceiptModalVisible, posStore } = props

  const [latestShiftDetail, setLatestShiftDetail] = React.useState<IShift>()
  const [showInventorySold, setShowInventorySold] = React.useState(false)

  React.useEffect(() => {
    ;(async function getShift() {
      try {
        const shiftDetail = await API.getShiftById(posStore!.company.id, lastShiftId)
        setLatestShiftDetail(shiftDetail)
      } catch (error) {
        message.error(
          parseErrorMsg(error) || 'An error occurred while getting the latest shift info',
        )
      }
    })()
  }, [])

  return (
    <Modal
      maskClosable={false}
      title='Receipt'
      visible={visible}
      footer={false}
      onCancel={() => setIsReceiptModalVisible(false)}
    >
      {latestShiftDetail && (
        <div>
          <ShiftReceipt
            company={posStore!.company!}
            shift={latestShiftDetail}
            showInventorySold={showInventorySold}
          />

          <Checkbox
            checked={showInventorySold}
            onClick={() => setShowInventorySold(!showInventorySold)}
          >
            Show inventory sold
          </Checkbox>

          <BlazeButton
            buttonProps={{
              icon: 'printer',
              type: 'primary',
              className: 'w-100 mt-4',
              onClick: () => printHTML('print-section', posStore!.company.name),
            }}
            titleProps={{ title: 'Print end-of-shift receipt' }}
          />
        </div>
      )}
    </Modal>
  )
})
