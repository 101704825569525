import React, { Component } from 'react'
import { AppView, AppMainView } from '../../../components/AppView'
import { PageHeader, Skeleton, Alert, Icon, Progress, Tag } from 'antd'
import { ICompany, API, IProductMetaDataItem } from '@getgreenline/homi-shared'
import { browserHistory, RouteComponentProps } from 'react-router'
import CSVReader from 'react-csv-reader'
import { GrPrice, GrRegex } from '../../../utilities/helpers'
import {
  CreateProduct,
  MetaDataReservedKeys,
  CreateChildProduct,
} from '../../Dashboard/DashboardProducts/AddProduct/ProductStore'
import { isBoolean } from 'util'
import { COLORS } from '../../../constants/Colors'
import { getIndex } from './AdminProductImport'
import { CSVProductHeaders } from '../../../constants/CSVHeaders'
import { SpecialCharacterProducts } from './SpecialCharacterProducts'
import { productApi } from '@getgreenline/products'

type TWarningType = 'Name' | 'SKU' | 'Barcode'

export interface ICSVProductUpdate {
  id: string
  sku?: string
  parentSku?: string
  name?: string
  imageUrl?: string
  isActive?: boolean
  price?: number
  depositFee?: number
  purchasePrice?: number
  description?: string
  barcode?: string
  shortDescription?: string
  weight?: number
  cannabisWeight?: number
  cannabisVolume?: number
  isBatchTracked?: boolean
  metaData?: IProductMetaDataItem[]
}

interface INestedCSVProduct extends ICSVProductUpdate {
  childProducts: ICSVProductUpdate[]
}

interface Params {
  companyId: string
}
type Props = RouteComponentProps<Params, {}>

interface State {
  company?: ICompany
  isImporting: boolean
  headers?: string[]
  data?: any[]
  csvProducts?: INestedCSVProduct[]
  importedRows: number
  skippedRows: ICSVProductUpdate[]
}

export class AdminProductUpdate extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isImporting: false,
      importedRows: 0,
      skippedRows: [],
    }
  }

  componentDidMount() {
    this.loadCompany()
  }

  loadCompany = async () => {
    const { params } = this.props
    const companyId = parseInt(params.companyId)
    const company = await API.getCompanyById(companyId, false)
    this.setState({ company })
  }

  onFileLoad = (data: any) => {
    if (data.length < 1) {
      return
    }
    this.setState({ headers: Object.keys(data[0]) })
    this.setState({ data })
    this.getNestedMappedProducts()
  }

  isValid = (value: string | number | boolean | null) => {
    return !(value === null || value === '')
  }

  get csvProducts(): ICSVProductUpdate[] {
    if (!this.state.data) return []

    const csvProducts: ICSVProductUpdate[] = []

    for (const product of this.state.data) {
      const csvId = product[CSVProductHeaders.ID]
      const csvSku = product[CSVProductHeaders.SKU]
      const csvParentSku = product[CSVProductHeaders.PARENT_SKU]
      const csvName = product[CSVProductHeaders.NAME]
      const csvImage = product[CSVProductHeaders.IMAGE_URL]
      const csvIsActive =
        product[CSVProductHeaders.IS_ACTIVE] && product[CSVProductHeaders.IS_ACTIVE] === 'TRUE'
      const csvPrice = product[CSVProductHeaders.PRICE]
        ? GrPrice.convertDollarToCent(product[CSVProductHeaders.PRICE])
        : null
      const csvDepositFee = product[CSVProductHeaders.DEPOSIT_FEE]
        ? GrPrice.convertDollarToCent(product[CSVProductHeaders.DEPOSIT_FEE])
        : null
      const csvPurchasePrice = product[CSVProductHeaders.PURCHASE_PRICE]
        ? GrPrice.convertDollarToCent(product[CSVProductHeaders.PURCHASE_PRICE])
        : null
      const csvDescription = product[CSVProductHeaders.DESCRIPTION]
      const csvBarcode = product[CSVProductHeaders.BARCODE]
      const csvShortDescription = product[CSVProductHeaders.SHORT_DESCRIPTION]
      const csvWeight = product[CSVProductHeaders.WEIGHT]
        ? parseFloat(product[CSVProductHeaders.WEIGHT])
        : null
      const csvCannabisWeight = product[CSVProductHeaders.CANNABIS_WEIGHT]
        ? parseFloat(product[CSVProductHeaders.CANNABIS_WEIGHT])
        : null
      const csvCannabisVolume = product[CSVProductHeaders.CANNABIS_VOLUME]
        ? parseFloat(product[CSVProductHeaders.CANNABIS_VOLUME])
        : null
      const csvTHC = product[CSVProductHeaders.THC] ? product[CSVProductHeaders.THC] : null
      const csvCBD = product[CSVProductHeaders.CBD] ? product[CSVProductHeaders.CBD] : null
      const csvMaxCBD = product[CSVProductHeaders.MAX_CBD]
      const csvMinCBD = product[CSVProductHeaders.MIN_CBD]
      const csvMaxTHC = product[CSVProductHeaders.MAX_THC]
      const csvMinTHC = product[CSVProductHeaders.MIN_THC]
      const csvShowMaxMin = product[CSVProductHeaders.SHOW_MAX_MIN]
      const csvUnit = product[CSVProductHeaders.UNIT]
      const isBatchTracked =
        (product[CSVProductHeaders.TRACK_LOTS] || '').toUpperCase().trim() === 'TRUE'

      if (!this.isValid(csvId)) {
        continue
      }

      const csvProduct = { id: csvId }

      const csvProductMetaData: IProductMetaDataItem[] = []
      if (csvTHC) {
        csvProductMetaData.push({ metaKey: MetaDataReservedKeys.THC, metaValue: csvTHC })
      }
      if (csvCBD) {
        csvProductMetaData.push({ metaKey: MetaDataReservedKeys.CBD, metaValue: csvCBD })
      }
      if (csvMaxCBD) {
        csvProductMetaData.push({ metaKey: MetaDataReservedKeys.MAX_CBD, metaValue: csvMaxCBD })
      }
      if (csvMinCBD) {
        csvProductMetaData.push({ metaKey: MetaDataReservedKeys.MIN_CBD, metaValue: csvMinCBD })
      }
      if (csvMaxTHC) {
        csvProductMetaData.push({ metaKey: MetaDataReservedKeys.MAX_THC, metaValue: csvMaxTHC })
      }
      if (csvMinTHC) {
        csvProductMetaData.push({ metaKey: MetaDataReservedKeys.MIN_THC, metaValue: csvMinTHC })
      }
      if (csvShowMaxMin) {
        csvProductMetaData.push({
          metaKey: MetaDataReservedKeys.SHOW_MAX_MIN,
          metaValue: csvShowMaxMin,
        })
      }
      if (csvUnit) {
        csvProductMetaData.push({ metaKey: MetaDataReservedKeys.UNIT, metaValue: csvUnit })
      }

      // Filter out empty cells in CSV, as they should not be updated
      Object.assign(
        csvProduct,
        this.isValid(csvSku) && { sku: csvSku },
        this.isValid(csvParentSku) && { parentSku: csvParentSku },
        this.isValid(csvName) && { name: csvName },
        this.isValid(csvImage) && { imageUrl: csvImage },
        this.isValid(csvIsActive) && { isActive: csvIsActive },
        this.isValid(csvPrice) && { price: csvPrice },
        this.isValid(csvDepositFee) && { depositFee: csvDepositFee },
        this.isValid(csvPurchasePrice) && { purchasePrice: csvPurchasePrice },
        this.isValid(csvDescription) && { description: csvDescription },
        this.isValid(csvBarcode) && { barcode: csvBarcode },
        this.isValid(csvShortDescription) && { shortDescription: csvShortDescription },
        this.isValid(csvWeight) && { weight: csvWeight },
        this.isValid(csvCannabisWeight) && { cannabisWeight: csvCannabisWeight },
        this.isValid(csvCannabisVolume) && { cannabisVolume: csvCannabisVolume },
        { isBatchTracked },
        csvProductMetaData.length > 0 && { metaData: csvProductMetaData },
      )

      const cleanedProduct = GrRegex.cleanFrequentSpecialCharacters(
        JSON.stringify(csvProduct),
        true,
      )

      csvProducts.push(JSON.parse(cleanedProduct))
    }

    return csvProducts
  }

  getNestedMappedProducts = () => {
    const nestedCSVProducts: INestedCSVProduct[] = []

    // Sort variants to end of array, so its parent product can be created first
    const sortedProducts = this.csvProducts.sort((a, b) => {
      if (!a.parentSku) {
        return -1
      }
      if (b.parentSku) {
        return 1
      }
      return 0
    })

    for (const csvProduct of sortedProducts) {
      if (csvProduct.parentSku) {
        const matchingParentProduct = nestedCSVProducts.find(
          (product) => product.sku === csvProduct.parentSku,
        )
        if (matchingParentProduct) {
          matchingParentProduct.childProducts.push(csvProduct)
        }
      } else {
        nestedCSVProducts.push({ ...csvProduct, childProducts: [] })
      }
    }

    this.setState({ csvProducts: nestedCSVProducts })
  }

  getExistingProduct = async (productId: string) => {
    const { company } = this.state
    if (!company) {
      return
    }
    const product = new CreateProduct()

    try {
      const existingProduct = await API.getProductById(company.id, productId, true)
      product.populateFields(existingProduct)
      return product
    } catch (error) {
      throw new Error(error)
    }
  }

  submit = async () => {
    const { csvProducts, company } = this.state
    if (!csvProducts || !company) {
      return
    }

    if (company && window.confirm('Are you sure? Please stay on this page while importing.')) {
      this.setState({ isImporting: true })

      window.onbeforeunload = function () {
        return true
      }

      for (const csvProduct of csvProducts) {
        try {
          const updateProduct = await this.getExistingProduct(csvProduct.id)
          if (!updateProduct) {
            return
          }

          if (updateProduct.childProducts.length > 0) {
            updateProduct.childProducts.map((variant) => {
              const csvVariant = csvProduct.childProducts.find((csvVar) => csvVar.id === variant.id)
              if (!csvVariant) {
                return variant
              }
              this.setNewValues(csvVariant, variant)
            })
          }

          const updateObject = this.setNewValues(csvProduct, updateProduct).networkObject

          await productApi.updateProduct(company.id, csvProduct.id, updateObject)
          this.setState({ importedRows: this.state.importedRows + 1 })
        } catch (error) {
          this.setState({
            skippedRows: [...this.state.skippedRows, csvProduct],
          })
        }
      }

      window.onbeforeunload = null
    }
  }

  setNewValues = (
    csvProduct: INestedCSVProduct | ICSVProductUpdate,
    updateProduct: CreateProduct | CreateChildProduct,
  ) => {
    if (csvProduct.sku) {
      updateProduct.setSKU(csvProduct.sku)
    }
    if (csvProduct.name) {
      updateProduct.setName(csvProduct.name)
    }
    if (csvProduct.imageUrl) {
      updateProduct.setImageUrl(csvProduct.imageUrl)
    }
    if (isBoolean(csvProduct.isActive)) {
      updateProduct.setIsActive(csvProduct.isActive)
    }
    if (csvProduct.price) {
      updateProduct.setPrice(csvProduct.price)
    }
    if (csvProduct.depositFee) {
      updateProduct.setDepositFee(csvProduct.depositFee)
    }
    if (csvProduct.purchasePrice) {
      updateProduct.setPurchasePrice(csvProduct.purchasePrice)
    }
    if (csvProduct.description) {
      updateProduct.setDescription(csvProduct.description)
    }
    if (csvProduct.barcode) {
      updateProduct.setBarcode(csvProduct.barcode)
    }
    if (csvProduct.shortDescription) {
      updateProduct.setShortDescription(csvProduct.shortDescription)
    }
    if (csvProduct.weight) {
      updateProduct.setWeight(csvProduct.weight)
    }
    if (csvProduct.cannabisWeight) {
      updateProduct.setCannabisWeight(csvProduct.cannabisWeight)
    }
    if (csvProduct.cannabisVolume) {
      updateProduct.setCannabisVolume(csvProduct.cannabisVolume)
    }

    if (updateProduct instanceof CreateProduct) {
      if (
        csvProduct.isBatchTracked === true &&
        this.state.company!.isBatchTracking &&
        updateProduct.inventoryMode !== 'bulk'
      ) {
        updateProduct.setIsBatchTracked(csvProduct.isBatchTracked)
      }
    }

    if (csvProduct.metaData) {
      for (const metaData of csvProduct.metaData) {
        updateProduct.metaData.setValue(metaData.metaKey, metaData.metaValue)
      }
    }

    return updateProduct
  }

  returnAlert = (type: TWarningType, list: INestedCSVProduct[]) => {
    return (
      <Alert
        type='warning'
        message={`${type} warnings`}
        style={{ marginBottom: 10 }}
        description={
          <>
            <b>Please check the {type.toLowerCase()} of the following rows:</b>
            <ul>
              {list.map((product, index) => (
                <li
                  key={index}
                >{`SKU: ${product.sku}, Name: ${product.name}, Barcode: ${product.barcode}`}</li>
              ))}
            </ul>
          </>
        }
      />
    )
  }

  get headerMapping() {
    const { headers } = this.state
    if (!headers) {
      return
    }

    return (
      <>
        <div className='d-flex justify-content-between'>
          <b>Headers</b>
          <span>
            <Icon type='check-circle' theme='twoTone' twoToneColor={COLORS.green400} />
            <span className='ml-1 mr-2'>present</span>
            <Icon type='close-circle' theme='twoTone' twoToneColor={COLORS.red400} />
            <span className='ml-1 mr-2'>missing</span>
            <Icon type='exclamation-circle' theme='twoTone' twoToneColor={COLORS.amber} />
            <span className='ml-1 mr-2'>ignored</span>
          </span>
        </div>
        {this.headerMappingDisplay(headers, CSVProductHeaders.ID)}
        {this.headerMappingDisplay(headers, CSVProductHeaders.SKU)}
        {this.headerMappingDisplay(headers, CSVProductHeaders.PARENT_SKU)}
        {this.headerMappingDisplay(headers, CSVProductHeaders.NAME)}
        {this.headerMappingDisplay(headers, CSVProductHeaders.IMAGE_URL)}
        {this.headerMappingDisplay(headers, CSVProductHeaders.IS_ACTIVE)}
        {this.headerMappingDisplay(headers, CSVProductHeaders.PRICE)}
        {this.headerMappingDisplay(headers, CSVProductHeaders.DEPOSIT_FEE)}
        {this.headerMappingDisplay(headers, CSVProductHeaders.PURCHASE_PRICE)}
        {this.headerMappingDisplay(headers, CSVProductHeaders.BARCODE)}
        {this.headerMappingDisplay(headers, CSVProductHeaders.DESCRIPTION)}
        {this.headerMappingDisplay(headers, CSVProductHeaders.SHORT_DESCRIPTION)}
        {this.headerMappingDisplay(headers, CSVProductHeaders.WEIGHT)}
        {this.headerMappingDisplay(headers, CSVProductHeaders.CANNABIS_WEIGHT)}
        {this.headerMappingDisplay(headers, CSVProductHeaders.CANNABIS_VOLUME)}
        {this.headerMappingDisplay(headers, CSVProductHeaders.THC)}
        {this.headerMappingDisplay(headers, CSVProductHeaders.CBD)}
        {this.headerMappingDisplay(headers, CSVProductHeaders.MIN_THC)}
        {this.headerMappingDisplay(headers, CSVProductHeaders.MAX_THC)}
        {this.headerMappingDisplay(headers, CSVProductHeaders.MIN_CBD)}
        {this.headerMappingDisplay(headers, CSVProductHeaders.MAX_CBD)}
        {this.headerMappingDisplay(headers, CSVProductHeaders.SHOW_MAX_MIN)}
        {this.headerMappingDisplay(headers, CSVProductHeaders.UNIT)}
        {this.headerMappingDisplay(
          headers,
          CSVProductHeaders.TRACK_LOTS,
          !this.state.company!.isBatchTracking,
        )}
      </>
    )
  }

  get warnings() {
    const { csvProducts } = this.state
    if (!csvProducts) {
      return
    }
    const warnings: JSX.Element[] = []

    // Names cannot contain special characters
    const badNames = csvProducts.filter((p) => p.name && p.name.match(GrRegex.unicodeRegex))

    // When saving CSV files in Excel, large numbers may be automatically converted to scientific notation
    const badBarcodes = csvProducts.filter(
      (p) => p.barcode && (p.barcode.includes('E+') || p.barcode.includes('e+')),
    )
    const badSkus = csvProducts.filter(
      (p) => p.sku && (p.sku.includes('E+') || p.sku.includes('e+')),
    )

    if (badNames && badNames.length > 0) {
      warnings.push(this.returnAlert('Name', badNames))
    }

    if (badBarcodes && badBarcodes.length > 0) {
      warnings.push(this.returnAlert('Barcode', badBarcodes))
    }

    if (badSkus && badSkus.length > 0) {
      warnings.push(this.returnAlert('SKU', badSkus))
    }

    return warnings
  }

  headerMappingDisplay = (headers: string[], columnName: string, isIgnored?: boolean) => {
    const index = getIndex(headers, columnName)
    let type = index === null ? 'close-circle' : 'check-circle'
    let color = index === null ? COLORS.red400 : COLORS.green400

    if (isIgnored) {
      type = 'exclamation-circle'
      color = COLORS.amber
    }

    return (
      <div key={columnName}>
        <Icon type={type} theme='twoTone' twoToneColor={color} />
        <span className='ml-2'>{columnName}</span>
      </div>
    )
  }

  render() {
    const { company, csvProducts, importedRows, skippedRows } = this.state

    return (
      <AppView column>
        <AppMainView>
          {!company ? (
            <Skeleton active />
          ) : (
            <>
              <PageHeader
                title={company ? `${company.name} - Product CSV bulk update` : 'Loading...'}
                subTitle={company ? company.province : ''}
                onBack={() =>
                  browserHistory.push(
                    company ? `/admin/companies/${company.id}` : `/admin/companies`,
                  )
                }
              />
              <div className='container'>
                <Alert
                  type='info'
                  className='mb-4'
                  message='CSV template'
                  description={
                    <>
                      <p>
                        Updating products requires the following columns to be available
                        (case-sensitive):
                      </p>
                      <p>Note: Only columns that are in the CSV will be updated</p>
                      <div className='row'>
                        <div className='col-md-6'>
                          <p>
                            <b>Required CSV columns</b>
                          </p>
                          <ul>
                            <li>
                              {CSVProductHeaders.ID} <span className='text-danger'>*required</span>
                            </li>
                          </ul>
                        </div>
                        <div className='col-md-6'>
                          <p>
                            <b>Optional CSV columns</b>
                          </p>
                          <ul>
                            <li>{CSVProductHeaders.SKU}</li>
                            <li>{CSVProductHeaders.PARENT_SKU}</li>
                            <ul>
                              <li>Required for parent/variant relationships</li>
                            </ul>
                            <li>{CSVProductHeaders.NAME}</li>
                            <li>{CSVProductHeaders.IMAGE_URL}</li>
                            <li>{CSVProductHeaders.IS_ACTIVE}</li>
                            <ul>
                              <li>true or false</li>
                            </ul>
                            <li>{CSVProductHeaders.PRICE}</li>
                            <ul>
                              <li>Can optionally include the $ symbol</li>
                            </ul>
                            <li>{CSVProductHeaders.DEPOSIT_FEE}</li>
                            <ul>
                              <li>Can optionally include the $ symbol</li>
                            </ul>
                            <li>{CSVProductHeaders.PURCHASE_PRICE}</li>
                            <ul>
                              <li>Can optionally include the $ symbol</li>
                            </ul>
                            <li>{CSVProductHeaders.BARCODE}</li>
                            <li>{CSVProductHeaders.DESCRIPTION}</li>
                            <li>{CSVProductHeaders.SHORT_DESCRIPTION}</li>
                            <li>{CSVProductHeaders.WEIGHT}</li>
                            <li>{CSVProductHeaders.CANNABIS_WEIGHT}</li>
                            <li>{CSVProductHeaders.CANNABIS_VOLUME}</li>
                            <li>{CSVProductHeaders.THC}</li>
                            <li>{CSVProductHeaders.CBD}</li>
                            <li>{CSVProductHeaders.MIN_THC}</li>
                            <li>{CSVProductHeaders.MAX_THC}</li>
                            <li>{CSVProductHeaders.MIN_CBD}</li>
                            <li>{CSVProductHeaders.MAX_CBD}</li>
                            <li>{CSVProductHeaders.SHOW_MAX_MIN}</li>
                            <ul>
                              <li>true or false</li>
                            </ul>
                            <li>{CSVProductHeaders.UNIT}</li>
                            <ul>
                              <li>Example: mg/g</li>
                            </ul>
                            <li>{CSVProductHeaders.TRACK_LOTS}</li>
                            <ul>
                              <li>true or false</li>
                              {!company.isBatchTracking && (
                                <li>
                                  <span>
                                    Will be <b>ignored</b> because lot-tracking is{' '}
                                  </span>
                                  <Tag className='mr-1' color='red'>
                                    disabled
                                  </Tag>
                                  <span>at the company level. </span>
                                  <span>
                                    Enable company level lot-tracking before importing products
                                  </span>
                                </li>
                              )}
                              <li>
                                Once enabled, product level lot-tracking <b>cannot</b> be disabled
                                again
                              </li>
                            </ul>
                          </ul>
                        </div>
                      </div>
                    </>
                  }
                />

                {!csvProducts && (
                  <CSVReader
                    cssClass='csv-input'
                    parserOptions={{ header: true }}
                    onFileLoaded={(data: any) => {
                      this.onFileLoad(data)
                    }}
                    onError={(error: any) => {
                      console.error(error)
                    }}
                    inputId='csv-id'
                  />
                )}

                {csvProducts && this.state.isImporting && (
                  <div className='container'>
                    <Progress
                      percent={Math.round(
                        ((importedRows + skippedRows.length) / csvProducts.length) * 100,
                      )}
                    />
                    <div className='text-success'>Imported products: {importedRows}</div>
                    <div className='text-danger'>
                      Skipped/errored products: {skippedRows.length}
                    </div>
                    <div>Total products: {csvProducts.length}</div>
                    <div>Skipped/errored products log:</div>
                    <div className='bg-light' style={{ maxHeight: 250, overflowY: 'scroll' }}>
                      <pre>{JSON.stringify(this.state.skippedRows, null, 2)}</pre>
                    </div>
                  </div>
                )}

                {csvProducts && !this.state.isImporting && (
                  <div>
                    <b>Number of products (not including variants):</b>
                    <div className='mb-4'>{csvProducts.length}</div>

                    {this.warnings}

                    <div className='row'>
                      <div className='col-md-6'>
                        {this.headerMapping}

                        <div>
                          <SpecialCharacterProducts
                            csvProducts={this.csvProducts}
                            submit={this.submit}
                          />
                        </div>
                      </div>

                      <div className='col-md-6'>
                        <div>Preview (first 10 results)</div>
                        <div className='bg-light' style={{ maxHeight: 800, overflowY: 'scroll' }}>
                          <pre>{JSON.stringify(csvProducts.slice(0, 10), null, 2)}</pre>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </AppMainView>
      </AppView>
    )
  }
}
