export enum CSVProductHeaders {
  ID = 'ID',
  SKU = 'SKU',
  PARENT_SKU = 'Parent SKU',
  NAME = 'Name',
  PARENT_NAME = 'Parent Name',
  IMAGE_URL = 'Image URL',
  IS_ACTIVE = 'Is Active',
  PRICE = 'Retail Price',
  CATEGORY = 'Category',
  BRAND = 'Brand',
  DEPOSIT_FEE = 'Deposit Fee',
  PURCHASE_PRICE = 'Wholesale Cost',
  DESCRIPTION = 'Description',
  BARCODE = 'Barcode',
  SHORT_DESCRIPTION = 'Receipt Description',
  WEIGHT = 'Net Product Weight',
  CANNABIS_WEIGHT = 'Cannabis Weight',
  CANNABIS_VOLUME = 'Cannabis Volume',
  THC = 'THC',
  CBD = 'CBD',
  MAX_CBD = 'Max CBD',
  MIN_CBD = 'Min CBD',
  MAX_THC = 'Max THC',
  MIN_THC = 'Min THC',
  SHOW_MAX_MIN = 'Show Min Max THC CBD',
  UNIT = 'THC/CBD Unit',
  TRACK_LOTS = 'Track Lots',
  CUSTOM_HEADER_PREFIX = 'Custom-',
}
