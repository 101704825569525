import { Menu } from 'antd-v4'
import React from 'react'
import { Link } from 'react-router'
import { Icon } from 'antd'
import { Routes } from '../../../constants/Routes'

const { SubMenu } = Menu
const defaultIconStyles = { width: 14, height: 14 }

export const AdminMenu = () => {
  const pathname = window.location.pathname.split('/').pop()
  return (
    <Menu defaultSelectedKeys={[pathname || '']} mode='horizontal' theme='dark'>
      <Menu.Item key='companies'>
        <Link to={'/admin/v2/companies'}>
          <>
            <Icon type='home' style={defaultIconStyles} />
            Companies
          </>
        </Link>
      </Menu.Item>
      <Menu.Item key='integrations'>
        <Link to={'/admin/v2/integrations'}>
          <>
            <Icon type='appstore' style={defaultIconStyles} />
            Integrations
          </>
        </Link>
      </Menu.Item>
      <Menu.Item key='organizations'>
        <Link to={'/admin/v2/organizations'}>
          <>
            <Icon type='apartment' style={defaultIconStyles} />
            Organizations
          </>
        </Link>
      </Menu.Item>
      <Menu.Item key='development'>
        <Link to={'/admin/v2/development'}>
          <>
            <Icon type='setting' style={defaultIconStyles} />
            Development
          </>
        </Link>
      </Menu.Item>
      <SubMenu
        key='provinceProducts'
        title='Province Products'
        icon={<Icon type='shopping' style={defaultIconStyles} />}
      >
        <Menu.Item key='search'>
          <Link to={'/admin/v2/provinceProducts/search'}>
            <>
              <Icon type='search' style={defaultIconStyles} />
              Search
            </>
          </Link>
        </Menu.Item>
        <Menu.Item key='export'>
          <Link to={'/admin/v2/provinceProducts/export'}>
            <>
              <Icon type='export' style={defaultIconStyles} />
              Export
            </>
          </Link>
        </Menu.Item>
        <Menu.Item key='import'>
          <Link to={'/admin/v2/provinceProducts/import'}>
            <>
              <Icon type='import' style={defaultIconStyles} />
              Import
            </>
          </Link>
        </Menu.Item>
      </SubMenu>
      <Menu.Item key='register'>
        <Link to={Routes.REGISTER}>
          <>
            <Icon type='setting' style={defaultIconStyles} />
            Register
          </>
        </Link>
      </Menu.Item>
      <Menu.Item key='styleGuidelines'>
        <Link to={Routes.STYLE_GUIDELINES}>
          <>
            <Icon type='setting' style={defaultIconStyles} />
            Style Guidelines
          </>
        </Link>
      </Menu.Item>
    </Menu>
  )
}
